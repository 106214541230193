import React from "react"
import { createBrowserRouter, Navigate } from "react-router-dom"
import Register from "./pages/Authentication/Register"
import Login from "./pages/Authentication/Login"
import ForgotPassword from "./pages/Authentication/ForgotPassword"
import ResetPassword from "./pages/Authentication/ResetPassword"
import Confirmation from "./pages/Authentication/Confirmation"
import SetRole from "./pages/Authentication/SetRole"
import BrandDashboard from "./pages/Brand/BrandDashboard"
import BrandAccount from "./pages/Brand/BrandAccount"
import PaymentProfile from "./pages/Brand/PaymentProfile"
import PaymentPlans from "./pages/Brand/PaymentPlans"
import Checkout from "./pages/Brand/Checkout"
import AuthGuard from "./components/Authentication/Guards/AuthGuard"
import RoleGuard from "./components/Authentication/Guards/RoleGuard"
import ExternalRedirect from "./components/Authentication/ExternalRedirect"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/login"} />,
  },
  {
    path: "",
    element: <AuthGuard />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgot_password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset_password",
        element: <ResetPassword />,
      },
      {
        path: "/set_role",
        element: <SetRole />,
      },
    ],
  },
  {
    path: "/confirm_account",
    element: <Confirmation />,
  },
  {
    path: "/brand",
    element: <RoleGuard role="brand" />,
    children: [
      {
        path: "/brand/dashboard",
        element: <BrandDashboard />,
      },
      {
        path: "/brand/account",
        element: <BrandAccount />,
      },
      {
        path: "/brand/payment-profile",
        element: <PaymentProfile />,
      },
      {
        path: "/brand/plans",
        element: <PaymentPlans />,
      },
      {
        path: "/brand/plans/:id/checkout",
        element: <Checkout />,
      },
    ],
  },
  {
    path: "/influencer/dashboard",
    element: <ExternalRedirect url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`} />,
  },
  {
    path: "/influencer/account",
    element: <ExternalRedirect url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`} />,
  },
  {
    path: "/influencer/payment-profile",
    element: <ExternalRedirect url={`${process.env.REACT_APP_LANDING_PAGE_URL}/creator`} />,
  },
])
