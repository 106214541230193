import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"

const notify = {
  success: (message) => {
    toast.success(message)
  },
  error: (message) => {
    toast.error(message)
  },
}

export default notify
