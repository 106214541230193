import React from "react"
import { Dialog } from "@mui/material"
import { Button, LoadingButton, TextInput } from "../CommonElements"
import * as Yup from "yup"
import { useFormik } from "formik"
import MainIcon from "../../../assets/images/refresh-insights-copy.svg"
import "./styles.scss"

const Modal = ({ isOpen, closeModal, content, callback }) => {
  return (
    <Dialog open={isOpen} onClose={closeModal} className="generic-popup">
      <div className="generic-modal-container">
        <div className="icon-container">
          <img src={MainIcon} alt="" className="icon" />
        </div>
        <div className="text">{content}</div>
        <div className="flex-container">
          <Button type="button" label="Cancel" onClick={closeModal} theme={{ backgroundColor: "#f6f7fb", color: "#000000", padding: "0.5rem 2rem", fontSize: "14px" }} />
          <Button type="button" label="Confirm" onClick={callback} theme={{ backgroundColor: "#94A4FF", color: "#ffffff", padding: "0.5rem 2rem", fontSize: "14px" }} />
        </div>
      </div>
    </Dialog>
  )
}

const FormModal = ({ isOpen, closeModal, isLoading, heading, callback }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      if (callback(values)) {
        resetForm()
      }
    },
  })

  const { values, touched, errors, resetForm } = formik

  const handleClose = () => {
    resetForm()
    closeModal()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} className="generic-modal">
      <div className="generic-modal-container">
        <div className="heading">{heading}</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-field-container">
            <TextInput label="Name" name="name" value={values.name} placeholder="" onChange={formik.handleChange("name")} error={touched.name && errors.name} />
          </div>
          <div className="flex-container">
            <Button type="button" label="Cancel" onClick={handleClose} theme={{ backgroundColor: "#f6f7fb", color: "#000000", padding: "0.5rem 2rem", fontSize: "14px" }} />
            <LoadingButton type="submit" label="Confirm" isLoading={isLoading} theme={{ backgroundColor: "#94A4FF", color: "#ffffff", padding: "0.5rem 2rem", fontSize: "14px" }} />
          </div>
        </form>
      </div>
    </Dialog>
  )
}

export { Modal, FormModal }
