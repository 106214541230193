import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as Sentry from "@sentry/react"

if (process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a502c62ab859f52a9cfbb2aa4c1c4b40@o4507207001899008.ingest.de.sentry.io/4507224870027345",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://hive-staging.brainxdemo.com", "https://hiveinfluence.io"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<App />)
