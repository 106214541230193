import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Header from "../../../components/Common/Header"
import NavLinks from "../../../components/Account/NavLinks"
import Profile from "../../../components/Account/Profile"
import Password from "../../../components/Account/Password"
import Payment from "../../../components/Account/Payment"
import Billing from "../../../components/Account/Billing"
import "./styles.scss"
import MenuIcon from "../../../assets/images/menu.svg"
import { useLocation } from "react-router-dom"
import notify from "../../../components/Common/Toast"
import useProfileService from "../../../services/useProfileService"
import { useNavigate } from "react-router-dom"

const BrandAccount = () => {
  const queryParams = new URLSearchParams(useLocation().search)
  const currentTab = queryParams.get("activeTab")
  let success = queryParams.get("redirect_status")
  const currentUser = useSelector((state) => state.auth?.user)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()
  const profileService = useProfileService()

  const [activeTab, setActiveTab] = useState(`${currentTab ? currentTab : "profile"}`)

  useEffect(() => {
    if (success) {
      notify.success("Payment method added successfully.")
      const updatedUrl = window.location.origin + window.location.pathname
      window.history.replaceState({}, document.title, `${updatedUrl}?activeTab=payment`)
      setTimeout(() => {
        profileService.getProfile()
        navigate("/brand/account?activeTab=payment", { replace: true })
      }, 2000)
    }
  }, [])

  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <section className="account-page">
      <Header />
      <div className="d-flex justify-content-center mb-5">
        <div className="profile-container">
          <div className="d-flex">
            <h1 className="primary-text h1 mt-5 mb-4">Account Settings</h1>
            <button className="menu-btn btn mt-5 mb-4 d-none" onClick={handleMenu}>
              <div className="icon-container">
                <img src={MenuIcon} className="icon" alt="" />
              </div>
            </button>
          </div>

          <div className="d-flex">
            <div>
              <div className="nav-section web me-4">
                <NavLinks activeTab={activeTab} setActiveTab={setActiveTab} />
              </div>
            </div>
            <div className="nav-content">
              <div class="tab-content">
                {activeTab == "profile" && <Profile currentUser={currentUser} />}
                {activeTab == "password" && <Password />}
                {activeTab == "payment" && <Payment />}
                {activeTab == "billing" && <Billing />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`backdrop ${isMenuOpen ? "isMenuOpen" : "hidden"}`} onClick={handleMenu}></div>
      <div className={`nav-section responsive ${isMenuOpen ? "d-block" : "d-none"}`} onClick={handleMenu}>
        <NavLinks />
      </div>
    </section>
  )
}

export default BrandAccount
