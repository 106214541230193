import React, { useState, useEffect } from "react";
import usePaymentMethodService from "../../../services/usePaymentMethodService";
import CardDetail from "./cardDetail";
import notify from "../../../components/Common/Toast";
import StripePaymentModal from "./PaymentMethod/StripePaymentMethodModal";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { useSelector } from "react-redux";
import AlertIcon from "../../../assets/images/payment_alert.svg";

const Payment = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const paymentMethodService = usePaymentMethodService();
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [defaultModalClicked, setDefaultModalClicked] = useState(false);
  const [removeModalClicked, setRemoveModalClicked] = useState(false);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();
  const currentUser = useSelector((state) => state.auth?.user);

  useEffect(() => {
    paymentMethodService
      .list()
      .then((data) => {
        setPaymentMethods(data);
      })
      .catch((error) => {
        notify.error(error);
      });
  }, [pageRefresh]);

  const handleSetDefault = () => {
    paymentMethodService
      .update(selectedPaymentMethodId)
      .then(() => {
        notify.success("Default changed successfully.");
        setDefaultModalClicked(false);
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => {
        notify.error(error);
      });
  };

  const handleRemove = () => {
    paymentMethodService
      .destroy(selectedPaymentMethodId)
      .then(() => {
        notify.success("Payment method removed successfully.");
        setRemoveModalClicked(false);
        setPageRefresh(!pageRefresh);
      })
      .catch((error) => {
        notify.error(error);
      });
  };

  const openRemoveModal = (id) => {
    setSelectedPaymentMethodId(id);
    setRemoveModalClicked(true);
  };

  const closeRemoveModal = () => {
    setRemoveModalClicked(false);
  };

  const openSetDefaultModal = (id) => {
    setSelectedPaymentMethodId(id);
    setDefaultModalClicked(true);
  };

  const closeSetDefaultModal = () => {
    setDefaultModalClicked(false);
  };

  return (
    <div>
      <div class="tab-pane">
        <div>
          {currentUser?.payment_method_error_message && (
            <div className="payment-alert-detail-section text-center mb-3">
              <img className="alert-icon-detail mx-2" src={AlertIcon} />
              {currentUser?.payment_method_error_message}
            </div>
          )}
          <div className="primary-text mb-5">Payment Methods</div>
          {paymentMethods.map((paymentMethod) => (
            <>
              <CardDetail
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                openRemoveModal={openRemoveModal}
                openSetDefaultModal={openSetDefaultModal}
              />
              <hr />
            </>
          ))}
        </div>
        <div
          className="new-payment-text cursor-pointer mt-4"
          onClick={() => {
            setStripeModalOpen(true);
          }}
        >
          + Add New Payment Method
        </div>

        {defaultModalClicked && (
          <ConfirmationModal
            open={true}
            onClose={closeSetDefaultModal}
            onConfirm={handleSetDefault}
            cancelButtonText={"Cancel"}
            confirmButtonText={"Confirm"}
            confirmButtonColor={"#94a4ff"}
            modalHeadingText={"Default Payment Method"}
            confirmationText={
              "Are you sure you want to make this payment method as default ?"
            }
          />
        )}

        {removeModalClicked && (
          <ConfirmationModal
            open={removeModalClicked}
            onClose={closeRemoveModal}
            onConfirm={handleRemove}
            cancelButtonText={"Cancel"}
            confirmButtonText={"Confirm"}
            confirmButtonColor={"#F65353"}
            modalHeadingText={"Remove Payment Method"}
            confirmationText={"Are you sure you want to remove this payment"}
          />
        )}
        {stripeModalOpen && (
          <StripePaymentModal
            stripeModalOpen={stripeModalOpen}
            setStripeModalOpen={setStripeModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default Payment;
