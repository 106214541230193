import React from "react"
import { RouterProvider } from "react-router-dom"
import { router } from "./Routes"
import { Provider } from "react-redux"
import { store, persistor } from "./redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { GoogleOAuthProvider } from "@react-oauth/google"
import ToastProvider from "./components/Common/ToastProvider"
import IntercomComponent from "./components/Common/Intercom"
import { FpjsProvider, FingerprintJSPro } from "@fingerprintjs/fingerprintjs-pro-react"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min"

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <FpjsProvider
          loadOptions={{
            apiKey: process.env.REACT_APP_FINGERPRINT_PUBLIC_KEY,
            endpoint: process.env.REACT_APP_FINGERPRINT_ENDPOINT || FingerprintJSPro.defaultEndpoint,
            scriptUrlPattern: process.env.REACT_APP_FINGERPRINT_ENDPOINT
              ? `${process.env.REACT_APP_FINGERPRINT_ENDPOINT}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`
              : FingerprintJSPro.defaultScriptUrlPattern,
            region: "ap",
          }}>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}>
            <RouterProvider router={router}></RouterProvider>
            <IntercomComponent />
            <ToastProvider />
          </GoogleOAuthProvider>
        </FpjsProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
