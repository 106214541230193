import React from "react"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
import "./styles.scss"
import LikeIcon from "../../../assets/images/post-likes.svg"
import CommentIcon from "../../../assets/images/post-comments.svg"
import ViewsIcon from "../../../assets/images/post-views.svg"
import PostTypeIcon from "../../../assets/images/post-type.svg"

const renderPosts = (posts, title) => {
  return (
    <div className="posts-container">
      <div className="heading">{title}</div>
      <div className="gallery">
        {posts?.map((post) => (
          <div className="post" key={post.id} onClick={() => window.open(post.link, "_blank")}>
            <div className="image-container">
              <Img src={post.thumbnail} className="image" loader={<Skeleton variant="rounded" width={274} height={238} />} />
            </div>
            {post?.media_type === "video" && (
              <div className="post-type">
                <img src={PostTypeIcon} className="icon" alt="" />
              </div>
            )}
            {post?.date && <div className="post-date">{post.date}</div>}
            <div className="reactions-container">
              {post?.likes && (
                <div className="reaction">
                  <div className="icon-container">
                    <img src={LikeIcon} className="icon" alt="" />
                  </div>
                  <div className="value">{post.likes}</div>
                </div>
              )}
              {post?.comments && (
                <div className="reaction">
                  <div className="icon-container">
                    <img src={CommentIcon} className="icon" alt="" />
                  </div>
                  <div className="value">{post.comments}</div>
                </div>
              )}
              {post?.views && (
                <div className="reaction">
                  <div className="icon-container">
                    <img src={ViewsIcon} className="icon" alt="" />
                  </div>
                  <div className="value">{post.views}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const Posts = ({ posts }) => {
  const postTypes = [
    { type: "popular", title: "Popular Posts" },
    { type: "recent", title: "Recent Posts" },
    { type: "sponsored", title: "Sponsored Posts" },
  ]

  return (
    <>
      {postTypes.map(({ type, title }) => {
        const filteredPosts = posts.filter((post) => post.type === type && post.thumbnail)
        return filteredPosts.length > 0 && renderPosts(filteredPosts, title)
      })}
    </>
  )
}

export default Posts
