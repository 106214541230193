import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Link, useNavigate } from "react-router-dom"
import useAuthService from "../../../services/useAuthService"
import { TextInput, Button, LoadingButton } from "../../../components/Common/CommonElements"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"
import LockIcon from "../../../assets/images/lock.svg"
import MailIcon from "../../../assets/images/mail.svg"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const authService = useAuthService()
  const [showForm, setShowForm] = useState(true)
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email is invalid").required("Email is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      authService
        .forgotPassword(values)
        .then((data) => {
          if (data) {
            setShowForm(false)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const { values, touched, errors } = formik

  return (
    <div className="forgot-password-page">
      <div className="logo-container">
        <img src={Logo} className="logo" alt="" />
      </div>
      <div className="form-container">
        {showForm ? (
          <form onSubmit={formik.handleSubmit} className="form">
            <div className="icon-container">
              <img src={LockIcon} className="icon" alt="lock-icon" />
            </div>
            <div className="primary-text mb-3">Forgot Password</div>
            <div className="secondary-text mb-5">
              Enter your registered email and we'll send reset <br /> instructions to the associated email.
            </div>
            <div className="form-fields-container">
              <div className="form-field-container">
                <TextInput label="Enter Your Email" name="email" value={values.email} onChange={formik.handleChange("email")} error={touched.email && errors.email} />
              </div>
            </div>
            <div style={{ marginBottom: "2rem" }}>
              <LoadingButton
                type="submit"
                label="Send password reset link"
                theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
                isLoading={loading}
              />
            </div>
            <div className="text-center">
              Back to{" "}
              <Link to="/login" className="link">
                Login
              </Link>
            </div>
          </form>
        ) : (
          <form className="form">
            <div className="icon-container">
              <img src={MailIcon} className="icon" alt="mail-icon" />
            </div>
            <div className="primary-text mb-3">Check your email</div>
            <div className="secondary-text mb-5">
              <strong>{formik.values.email}</strong> We'll send instructions for <br /> resetting your password. Did'nt get them ? Check the email <br /> address or ask
              to resend the instructions.
            </div>
            <Button
              type="button"
              label="Back to Login"
              onClick={() => navigate("/login")}
              theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
            />
          </form>
        )}
      </div>
    </div>
  )
}

export default ForgotPassword
