import React from "react"
import PieChart from "../Charts/PieChart"
import PercentageChart from "../Charts/PercentageChart"
import PeopleIcon from "@mui/icons-material/People"
import "./styles.scss"

const AudienceStats = ({ title, audience }) => {
  return (
    <div className="audience-container">
      <div className="heading">
        <PeopleIcon /> {title}
      </div>
      <div className="graphs-container">
        {audience.authenticity?.length > 0 && (
          <div className="half-width">
            <PieChart title="Audience Authenticity" values={audience.authenticity} />
          </div>
        )}
        {audience.countries?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Location by Country" values={audience.countries} />
          </div>
        )}
        {audience.ages?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Age" values={audience.ages} />
          </div>
        )}
        {audience.interests?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Interests" values={audience.interests} />
          </div>
        )}
        {audience.genders?.length > 0 && (
          <div className="half-width">
            <PieChart title="Audience Gender" values={audience.genders} />
          </div>
        )}
        {audience.brands?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Brand Affinity" values={audience.brands} />
          </div>
        )}
        {audience.languages?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Language" values={audience.languages} />
          </div>
        )}
        {audience.ethnicities?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Ethnicity" values={audience.ethnicities} />
          </div>
        )}
        {audience.cities?.length > 0 && (
          <div className="half-width">
            <PercentageChart title="Audience Location by City" values={audience.cities} />
          </div>
        )}
      </div>
    </div>
  )
}

export default AudienceStats
