import React from "react";

const NavLinks = ({ activeTab, setActiveTab }) => {
  return (
    <ul className="nav nav-tabs">
      <li className="nav-item mb-4">
        <button
          className={`nav-link ${activeTab == "profile" ? "active" : ""}`}
          data-bs-toggle="tab"
          type="button"
          onClick={() => {
            setActiveTab("profile");
          }}
        >
          Profile
        </button>
      </li>
      <li class="nav-item mb-4">
        <button
          className={`nav-link ${activeTab == "password" ? "active" : ""}`}
          data-bs-toggle="tab"
          data-bs-target="#password-tab-pane"
          type="button"
          onClick={() => {
            setActiveTab("password");
          }}
        >
          Change Password
        </button>
      </li>
      <li class="nav-item mb-4">
        <button
          className={`nav-link ${activeTab == "payment" ? "active" : ""}`}
          data-bs-toggle="tab"
          type="button"
          onClick={() => {
            setActiveTab("payment");
          }}
        >
          Payment Methods
        </button>
      </li>
      <li class="nav-item">
        <button
          className={`nav-link ${activeTab == "billing" ? "active" : ""}`}
          data-bs-toggle="tab"
          type="button"
          onClick={() => {
            setActiveTab("billing");
          }}
        >
          Plans & Billing
        </button>
      </li>
    </ul>
  );
};

export default NavLinks;
