import React, { useState } from "react"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import useAuthService from "../../../services/useAuthService"
import { TextInput, Button, LoadingButton } from "../../../components/Common/CommonElements"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"

const SetRole = () => {
  const navigate = useNavigate()
  const authService = useAuthService()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      role: "brand",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      companyName: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      authService
        .setRole(values)
        .then((data) => {
          if (data?.role === "brand") {
            navigate(`/${data.role}/dashboard`)
          } else if (data?.role === "influencer") {
            window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}/creator`
          } else {
            navigate("/login")
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const { values, touched, errors } = formik

  const handleSwitchRole = (role) => {
    formik.setFieldValue("role", role)
  }

  return (
    <div className="role-page">
      <div className="logo-container">
        <img src={Logo} className="logo" alt="" />
      </div>
      <div className="form-container">
        <form onSubmit={formik.handleSubmit} className="form">
          <div className="role-outer-container">
            <div className="role-inner-container">
              <Button
                type="button"
                label="I AM A BRAND"
                theme={{
                  backgroundColor: values.role === "brand" ? "#94a4ff" : "#f5f6fa",
                  color: values.role === "brand" ? "#000000" : "#555f61",
                  padding: "0.5rem 0.8rem",
                  fontSize: "14px",
                  width: "50%",
                }}
                onClick={() => handleSwitchRole("brand")}
              />
              <Button
                type="button"
                label="I AM A INFLUENCER"
                theme={{
                  backgroundColor: values.role === "influencer" ? "#94a4ff" : "#f5f6fa",
                  color: values.role === "influencer" ? "#000000" : "#555f61",
                  padding: "0.5rem 0.8rem",
                  fontSize: "14px",
                  width: "50%",
                }}
                onClick={() => (window.location.href = `${process.env.REACT_APP_LANDING_PAGE_URL}/creator`)}
              />
            </div>
          </div>
          <div className="form-fields-container">
            <TextInput label="Brand/Agency Name" name="name" value={values.name} onChange={formik.handleChange("name")} error={touched.name && errors.name} />
          </div>
          <div className="form-fields-container">
            <TextInput
              label="Company Name"
              name="companyName"
              value={values.companyName}
              onChange={formik.handleChange("companyName")}
              error={touched.companyName && errors.companyName}
            />
          </div>
          <LoadingButton
            type="submit"
            label="Confirm"
            theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
            isLoading={loading}
          />
        </form>
      </div>
    </div>
  )
}

export default SetRole
