import React, { useRef } from "react"
import { DropdownMenu, CheckboxInput } from "../../Common/CommonElements"
import { MenuItem } from "@mui/material"
import SearchIcon from "../../../assets/images/search.svg"
import "./styles.scss"

const SearchBar = ({ name, state, setState }) => {
  const timeoutRef = useRef(null)

  if (!(name in state)) {
    setState((prev) => ({
      ...prev,
      [name]: "",
    }))
  }

  const handleChange = async (event) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setState((prev) => ({
        ...prev,
        [name]: event.target.value.trim(),
      }))
    }, 300)
  }

  return (
    <div className="searchbar-container">
      <div className="search-icon-container">
        <img src={SearchIcon} className="icon" alt="" />
      </div>
      <input type="text" placeholder="Search..." className="searchbar" onChange={(e) => handleChange(e)} />
    </div>
  )
}

const BoardsFilter = ({ name, label, boards, state, setState }) => {
  if (!(name in state)) {
    setState((prev) => ({
      ...prev,
      [name]: [],
    }))
  }

  const handleCheckboxChange = (id) => (event) => {
    setState((prev) => ({
      ...prev,
      [name]: event.target.checked ? [...prev[name], id] : prev[name].filter((item) => item !== id),
    }))
  }

  return (
    <DropdownMenu label={label} theme={{ backgroundColor: "#ffffff", fontSize: "16px", border: "1px solid #e2e8f0", borderRadius: "25px" }}>
      {boards && boards.length > 0 ? (
        boards.map((board) => (
          <MenuItem key={board.id}>
            <div className="mui-dropdown-menu-item-container">
              <CheckboxInput label={board.name} value={state[name]?.includes(board.id)} onChange={handleCheckboxChange(board.id)} color="#000000" />
            </div>
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          <div className="mui-dropdown-menu-item-container">No boards to display</div>
        </MenuItem>
      )}
    </DropdownMenu>
  )
}

export { BoardsFilter, SearchBar }
