import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog } from "@mui/material"
import * as Yup from "yup"
import { useFormik } from "formik"
import { TextInput, LoadingButton } from "../../Common/CommonElements"
import "./styles.scss"
import CrossIcon from "../../../assets/images/cross.svg"

const NotesModal = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [value, setValue] = useState(null)
  const [callback, setCallback] = useState(null)

  const openModal = (id, value, callback) => {
    setId(id)
    setValue(value)
    setOpen(true)
    setCallback(() => callback)
  }

  const closeModal = () => {
    setId(null)
    setValue(null)
    setCallback(false)
    resetForm()
    setOpen(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      content: value,
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Content cannot be empty"),
    }),
    onSubmit: async (values) => {
      callback(values)
    },
  })

  const { values, touched, errors, resetForm } = formik

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    setLoading,
  }))

  return (
    <Dialog open={open} onClose={closeModal} className="notes-modal">
      <div className="modal-container">
        <button className="close-button" onClick={closeModal}>
          <img src={CrossIcon} alt="close" className="icon" />
        </button>
        <div className="modal-title">Add Note</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-field-container">
            <TextInput
              label={null}
              type="paragraph"
              name="content"
              rows={10}
              value={values.content}
              placeholder="Write a note"
              onChange={formik.handleChange("content")}
              error={touched.content && errors.content}
              isMulti={true}
            />
          </div>
          <LoadingButton
            type="submit"
            label="Submit"
            theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }}
            isLoading={loading}
          />
        </form>
      </div>
    </Dialog>
  )
})

export default NotesModal
