import React, { useState, useRef, forwardRef, useImperativeHandle } from "react"
import { useNavigate } from "react-router-dom"
import { Dialog } from "@mui/material"
import { useSelector } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import useBoardsService from "../../../services/useBoardsService"
import { LoadingButton, SelectInput, TextInput } from "../CommonElements"
import { currencies, platformsContent } from "../../../constants"
import TagsModal from "../../FindInfluencers/TagsModal"
import "./styles.scss"
import CrossIcon from "../../../assets/images/cross.svg"
import WarningIcon from "../../../assets/images/warning-triangle-icon.svg"

const BookmarkModal = forwardRef(({}, ref) => {
  const navigate = useNavigate()

  const boardService = useBoardsService()

  const tagsModalRef = useRef()
  const tagsModal = tagsModalRef.current

  const currentUser = useSelector((state) => state.auth?.user)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [influencers, setInfluencers] = useState([])
  const [boards, setBoards] = useState(null)
  const [tags, setTags] = useState([])
  const [callback, setCallback] = useState(null)

  const openModal = (influencers, callback) => {
    boardService.index().then((data) => {
      if (data) {
        setOpen(true)
        setInfluencers(influencers)
        setBoards(data)
        setCallback(() => callback)
      }
    })
  }

  const closeModal = () => {
    setInfluencers([])
    setTags([])
    resetForm()
    setOpen(false)
  }

  const addTag = ({ content, color }) => {
    setTags((prev) => [...prev, { tag: content, color: color }])
    tagsModal.closeModal()
  }

  const removeTag = (index) => {
    const newTags = [...tags.slice(0, index), ...tags.slice(index + 1)]
    setTags(newTags)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      board: null,
      column: null,
      platform: "",
      contentType: "",
      contentAmount: "",
      currency: "",
      fee: "",
      tags: [],
      influencers: [],
    },
    validationSchema: Yup.object({
      board: Yup.string().required("Please select a board"),
      column: Yup.string().required("Please select a column"),
      contentAmount: Yup.number("Enter a number").min(0, "Enter a valid number"),
      fee: Yup.number("Enter a number").min(0, "Enter a valid number"),
    }),
    onSubmit: async (values) => {
      values.influencers = influencers
      values.tags = tags
      callback(values)
    },
  })

  const { values, touched, errors, resetForm } = formik

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    setLoading,
  }))

  const restrictedByPlan = boards?.find((board) => board.id === values?.board)?.influencer_count + influencers.length > currentUser?.permissions.influencers

  return (
    <>
      <Dialog open={open} onClose={closeModal} className="bookmark-modal">
        <div className="modal-container">
          <button className="close-button" onClick={closeModal}>
            <img src={CrossIcon} alt="close" className="icon" />
          </button>
          <div className="modal-title">Add to board</div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-field-container">
              <SelectInput
                label="Board"
                placeholder="Select a board"
                name="board"
                value={values.board}
                onChange={formik.handleChange("board")}
                error={touched.board && errors.board}
                options={boards?.map((board) => ({ label: board.name, value: board.id }))}
                boardsSelect
              />
            </div>
            <div className="form-field-container">
              <SelectInput
                label="Column"
                placeholder="Select a column"
                name="column"
                value={values.column}
                onChange={formik.handleChange("column")}
                error={touched.column && errors.column}
                options={boards?.find((board) => board.id === values.board)?.columns.map((column) => ({ label: column.name, value: column.id })) || []}
                isDisabled={values.board === null}
                columnSelect
              />
            </div>
            <div className="form-label">Collaboration content (optional)</div>
            <div className="row-container">
              <div className="form-field-container" style={{ flex: "3 1 0" }}>
                <SelectInput
                  label="Platform"
                  placeholder="Platform"
                  name="platform"
                  value={values.platform}
                  onChange={formik.handleChange("platform")}
                  error={touched.platform && errors.platform}
                  options={platformsContent.map((platform) => ({ label: platform.name, value: platform.name }))}
                />
              </div>
              <div className="form-field-container" style={{ flex: "3 1 0" }}>
                <SelectInput
                  label="Type"
                  placeholder="Type"
                  name="contentType"
                  value={values.contentType}
                  onChange={formik.handleChange("contentType")}
                  error={touched.contentType && errors.contentType}
                  options={
                    platformsContent
                      .find((platform) => platform.name === values.platform)
                      ?.contentTypes.map((contentType) => ({ label: contentType, value: contentType })) || []
                  }
                />
              </div>
              <div className="form-field-container" style={{ flex: "3 1 0" }}>
                <TextInput
                  label="Amount"
                  placeholder="Amount"
                  name="contentAmount"
                  value={values.contentAmount}
                  onChange={formik.handleChange("contentAmount")}
                  error={touched.contentAmount && errors.contentAmount}
                />
              </div>
            </div>
            <div className="form-label">Fee (optional)</div>
            <div className="row-container">
              <div className="form-field-container" style={{ flex: "8 1 0" }}>
                <SelectInput
                  label="Select currency"
                  placeholder="Select currency"
                  name="currency"
                  value={values.currency}
                  onChange={formik.handleChange("currency")}
                  error={touched.currency && errors.currency}
                  options={currencies}
                />
              </div>
              <div className="form-field-container" style={{ flex: "4 1 0" }}>
                <TextInput label="Amount" placeholder="Amount" name="fee" value={values.fee} onChange={formik.handleChange("fee")} error={touched.fee && errors.fee} />
              </div>
            </div>
            <div className="form-label">Tags (optional)</div>
            <div className="tags-container">
              <div className="tags-button" onClick={() => tagsModal.openModal(null, addTag)}>
                + Add Tag
              </div>
              {tags.map((tag, index) => (
                <div key={index} style={{ backgroundColor: tag.color }} className="tag-item">
                  <span className="name">{tag.tag}</span>
                  <img src={CrossIcon} className="remove-button" alt="" onClick={() => removeTag(index)} />
                </div>
              ))}
            </div>
            {restrictedByPlan && (
              <div className="limit-exceeded-text">
                <img src={WarningIcon} alt="" className="icon" />
                <div className="text">Influencer limit exceeded for this board.</div>
                <div className="upgrade-button" onClick={() => navigate("/brand/plans")}>
                  Upgrade your plan
                </div>
              </div>
            )}
            <LoadingButton
              type="submit"
              label="Add to board"
              theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }}
              isLoading={loading}
              isDisabled={restrictedByPlan}
            />
          </form>
        </div>
      </Dialog>
      <TagsModal ref={tagsModalRef} />
    </>
  )
})

export default BookmarkModal
