export const isObjectEmpty = (object) => {
  if (!object || object.constructor !== Object) return true

  return Object.keys(object).every((key) => object[key] === null)
}

export const isArrayEmpty = (array) => {
  return Array.isArray(array) && array.length === 0
}

export const humanizeNumber = (value) => {
  if (value >= 1000000000) {
    return `${Math.floor(value / 1000000000)}B`
  } else if (value >= 1000000) {
    return `${Math.floor(value / 1000000)}M`
  } else if (value >= 1000) {
    return `${Math.floor(value / 1000)}K`
  } else {
    return value
  }
}

export const getRelativeUrl = (value) => {
  const url = new URL(value)
  const relativeUrl = url.pathname + url.search + url.hash
  return relativeUrl
}

export const isDayOld = (date) => {
  const duration = 24 * 60 * 60 * 1000
  const now = new Date()

  return now - date > duration
}
