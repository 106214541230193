import { useDispatch } from "react-redux"
import { setCredits } from "../redux/reducers/authReducer"
import useBaseService from "./useBaseService"
import notify from "../components/Common/Toast"

const useInfluencerService = () => {
  const dispatch = useDispatch()
  const axiosRequest = useBaseService().axiosRequest()

  const index = async (currentPlatform, currentPage, currentFilters, currentSorting) => {
    try {
      const response = await axiosRequest.get("influencers", {
        params: {
          platform: currentPlatform,
          page: currentPage,
          filters: JSON.stringify(currentFilters),
          sort: currentSorting["value"],
        },
      })
      dispatch(setCredits(response.data.meta.credits))
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const searchInfluencers = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/search", {
        params: {
          platform,
          query,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterLocations = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_locations", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterInterests = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_interests", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterLanguages = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_languages", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterBrands = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_brands", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterHashtags = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_hashtags", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const filterTopics = async (query, platform) => {
    try {
      const response = await axiosRequest.get("influencers/filter_topics", {
        params: {
          query,
          platform,
        },
      })
      return response.data
    } catch (error) {
      return false
    }
  }

  const savedInfluencers = async (currentPlatform, currentPage, filters) => {
    try {
      const response = await axiosRequest.get("saved_influencers", {
        params: {
          platform: currentPlatform,
          page: currentPage,
          query: filters.query,
          boards: filters.boards,
        },
      })
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeSavedInfluencer = async (id) => {
    try {
      const response = await axiosRequest.delete(`saved_influencers/${id}`)
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const saveFilters = async (name, url) => {
    try {
      await axiosRequest.post("saved_filters", {
        name,
        url,
      })
      notify.success("Filters have been saved and the link has been copied to the clipboard")
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  return {
    index,
    searchInfluencers,
    filterLocations,
    filterLanguages,
    filterBrands,
    filterInterests,
    filterHashtags,
    filterTopics,
    savedInfluencers,
    removeSavedInfluencer,
    saveFilters,
  }
}

export default useInfluencerService
