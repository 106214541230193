import React from "react"
import "./styles.scss"

const PercentageChart = ({ title, values }) => {
  return (
    <div className="percentage-graph">
      <div className="graph-heading">{title}</div>
      <div className="graph-rows-container">
        {values.map(({ key, value }, index) => (
          <div key={index} className="graph-row">
            <div className="flex-container">
              <div className="key">{key}</div>
              <div className="value">{value}%</div>
            </div>
            <div className="percentage-bar">
              <div style={{ width: `${value}%` }}>
                <div className="percentage-fill" style={{ width: `${value}%` }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PercentageChart
