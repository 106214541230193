import React from "react"
import { Button, CheckboxInput } from "../../Common/CommonElements"
import Skeleton from "@mui/material/Skeleton"
import "./styles.scss"

const SelectCards = ({ influencers, isLoading, selectedCards, handleCardBulkSelect, handleAddCardsToBoard }) => {
  return (
    <div className="select-cards-container">
      {isLoading && <Skeleton variant="rounded" width={100} height={24} />}
      {!isLoading && influencers?.length > 0 && (
        <>
          <CheckboxInput
            label={`${selectedCards?.length} Selected`}
            value={influencers?.map((influencer) => influencer.id).every((id) => selectedCards.includes(id))}
            onChange={handleCardBulkSelect}
            color="#94a4ff"
          />
          {selectedCards?.length > 0 && (
            <Button
              label="Add to board"
              onClick={() => handleAddCardsToBoard(selectedCards)}
              theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "0.3rem 1rem", fontSize: "12px" }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SelectCards
