import React, { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import useAuthService from "../../../services/useAuthService"
import { setRedirectUrl } from "../../../redux/reducers/authReducer"
import Icon from "../../../assets/images/hourglass.svg"
import { getRelativeUrl, isDayOld } from "../../../helpers"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
import "./styles.scss"

const Confirmation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authService = useAuthService()
  const [searchParams, setSearchParams] = useSearchParams()
  const [email, setEmail] = useState()

  const confirmationToken = searchParams.get("token")

  const { user, redirectUrl } = useSelector((state) => state.auth)

  const { isLoading, error, data, getData } = useVisitorData({ extendedResult: true }, { immediate: true })

  useEffect(() => {
    if (user && !isLoading && !error && data) {
      authService.addUserFingerprint(user?.id, data.visitorId)
    }
  }, [user, isLoading])

  useEffect(() => {
    if (confirmationToken) {
      authService.confirmation(confirmationToken).then((response) => {
        if (response?.error === "Email expired") {
          setEmail(response.email)
        } else if (response) {
          getData({ ignoreCache: true })
          if (redirectUrl) {
            if (!isDayOld(redirectUrl.createdAt)) navigate(getRelativeUrl(redirectUrl.url))
            dispatch(setRedirectUrl(null))
          } else {
            navigate(`/${response.role}/dashboard`)
          }
        } else {
          navigate("/login")
        }
      })
    }
  }, [])

  const handleResendConfirmation = async () => {
    authService.resendConfirmation(email)
  }

  return (
    <div className="account-confirmation-screen">
      <div className="center-container">
        <div className="icon">
          <img src={Icon} alt="" />
        </div>
        <div className="heading">{email ? "Email expired" : "Please wait while we confirm your account"}</div>
        {email && (
          <div className="link" onClick={handleResendConfirmation}>
            Click <span>here</span> to resend
          </div>
        )}
      </div>
    </div>
  )
}

export default Confirmation
