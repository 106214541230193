import React from "react"
import { useSelector } from "react-redux"
import infoIcon from "../../../assets/images/info_icon.svg"
import tickIcon from "../../../assets/images/tick.svg"

const PlanCard = ({ plan, switchState, planAndAmountHash, proceedToCheckout }) => {
  const currentUser = useSelector((state) => state.auth?.user)

  const featuresHash = {
    network: "Networks: Intagram, TikTok, Youtube",
    access: "Access Influence Search",
    core: "Core filters",
    advanced: "Advanced Filters",
    contact: "Influencers Contact Details",
    analytics: "Detail influence Analytics",
    boards: `Access to Boards: ${plan.features["boards"]}`,
    influencers: `Up to ${plan.features["influencers"]} influencers on boards`,
    credits: `${plan.features["credits"]} monthly Discovery credits`,
    audience: "Audience & authenticity",
    save: "Bulk Save",
  }

  const checkoutNotAllowed =
    planAndAmountHash &&
    ((planAndAmountHash["duration"] === "monthly" && switchState === "monthly" && planAndAmountHash["plan_id"] === plan.id) ||
      (planAndAmountHash["duration"] === "annually" && switchState === "monthly") ||
      (planAndAmountHash["duration"] === "annually" && planAndAmountHash["plan_id"] === plan.id))

  const currentPlanCheck = planAndAmountHash && planAndAmountHash["plan_id"] === plan.id && planAndAmountHash["duration"] === switchState
  const featureKeys = Object.keys(featuresHash)

  return (
    <div className="pricing_plan_card">
      {plan.name === "Professional" && <div className="most_popular">Most Popular</div>}
      <div className="plan_name">
        <img className="info_icon" src={infoIcon} alt="" />
        {plan.name}
        <div></div>
      </div>
      <div className="price_value d-flex justify-content-between align-items-center">
        <div>
          ${plan.prices_hash[`${switchState}`]["amount"]}
          <span className="per_month_text">/per month</span>
        </div>
        {currentPlanCheck && <span className="active-plan-badge">Current Plan</span>}
      </div>
      <div className="billed">Billed Monthly</div>
      <div className="offers_listing">
        {featureKeys?.map((key, index) => (
          <div key={index}>
            <img className="tick_cross_icon" src={tickIcon} alt="" />
            <span dangerouslySetInnerHTML={{ __html: featuresHash[key] }} />
          </div>
        ))}
      </div>
      <button className={checkoutNotAllowed ? "get_start_btn_disabled" : "get_start_btn"} onClick={() => proceedToCheckout(plan.id)}>
        {planAndAmountHash ? (currentPlanCheck ? "Current Plan" : "Upgrade") : currentUser ? "Upgrade" : "Get Started Free"}
      </button>
    </div>
  )
}

export default PlanCard
