import React from "react"
import { IconDropdownMenu } from "../../Common/CommonElements"
import EditIcon from "../../../assets/images/edit.svg"
import RemoveIcon from "../../../assets/images/remove.svg"
import DragIcon from "../../../assets/images/drag.svg"
import MenuItem from "@mui/material/MenuItem"
import "./styles.scss"

const BoardColumn = ({ id, name, handleEditColumn, handleRemoveColumn }) => {
  return (
    <div className="react-kanban-column-header">
      <div className="icon-container">
        <img src={DragIcon} alt="" className="icon" />
      </div>
      <div className="title">{name}</div>
      <div className="dropdown-container">
        <IconDropdownMenu>
          <MenuItem onClick={() => handleEditColumn(id, name)}>
            <div className="mui-dropdown-menu-item-container">
              <img src={EditIcon} alt="" className="dropdown-item-icon" />
              <div className="dropdown-item-text">Edit Column</div>
            </div>
          </MenuItem>
          <MenuItem onClick={() => handleRemoveColumn(id)}>
            <div className="mui-dropdown-menu-item-container">
              <img src={RemoveIcon} alt="" className="dropdown-item-icon" />
              <div className="dropdown-item-text" style={{ color: "red" }}>
                Remove Column
              </div>
            </div>
          </MenuItem>
        </IconDropdownMenu>
      </div>
    </div>
  )
}

export default BoardColumn
