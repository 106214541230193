import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import useProfileService from "../../../services/useProfileService"
import { TextInput, SelectInput, LoadingButton } from "../../../components/Common/CommonElements"
import { locations } from "../../../constants"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg"
import EditIcon from "../../../assets/images/image-edit-icon.svg"

const Profile = ({ currentUser }) => {
  const profileService = useProfileService()
  const [avatarImage, setAvatarImage] = useState(currentUser?.profile_picture_url || DefaultProfilePicture)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: null,
      name: currentUser?.name ?? "",
      companyName: currentUser?.company_name ?? "",
      email: currentUser?.email ?? "",
      phone: currentUser?.phone ?? "",
      location: currentUser?.location ?? "",
      website: currentUser?.website ?? "",
      address: currentUser?.address ?? "",
      bio: currentUser?.bio ?? "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      companyName: Yup.string(),
      email: Yup.string().email("Email is invalid").required("Email is required"),
      phone: Yup.string().matches(/^[\d+]+$/, "Enter a valid phone number"),
      location: Yup.string().required("Location is required"),
      website: Yup.string().matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\.[a-zA-Z]{2,})?$/, "Invalid website URL"),
      address: Yup.string(),
      bio: Yup.string(),
    }),
    onSubmit: async (values) => {
      const formData = new FormData()
      if (values.avatar !== null) {
        formData.append("profile_picture", values.avatar)
      }
      formData.append("name", values.name)
      formData.append("company_name", values.companyName)
      formData.append("email", values.email)
      formData.append("phone", values.phone)
      formData.append("location", values.location)
      formData.append("website", values.website)
      formData.append("address", values.address)
      formData.append("bio", values.bio)
      setIsLoading(true)
      profileService.updateProfile(formData).finally(() => {
        setIsLoading(false)
      })
    },
  })

  const { values, touched, errors, dirty } = formik

  useEffect(() => {
    setAvatarImage(currentUser?.profile_picture_url || DefaultProfilePicture)
  }, [currentUser])

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setAvatarImage(reader.result)
      }
      reader.readAsDataURL(file)
      formik.setFieldValue(event.target.name, file)
    }
  }

  return (
    <div>
      <div className="tab-pane">
        <form onSubmit={formik.handleSubmit} className="form">
          <div className="primary-text mb-4">Personal Information</div>
          <div className="form-fields-container">
            <div className="form-field-container">
              <div className="edit-avatar-container">
                <div className="avatar-container mb-4">
                  <Img src={avatarImage} className="avatar" loader={<Skeleton variant="rounded" width={150} height={150} />} />
                </div>
                <label htmlFor="avatar" className="edit-icon">
                  <div className="icon-container">
                    <img src={EditIcon} alt="" className="icon" />
                  </div>
                </label>
                <input
                  id="avatar"
                  name="avatar"
                  type="file"
                  className="d-none"
                  accept="image/*"
                  onChange={(event) => {
                    handleImageChange(event)
                  }}
                />
              </div>
            </div>
            <div className="form-field-container">
              <TextInput
                label="Full Name"
                name="name"
                value={values.name}
                placeholder="Enter your full name"
                onChange={formik.handleChange("name")}
                error={touched.name && errors.name}
              />
            </div>
            <div className="form-field-container">
              <TextInput
                label="Company Name"
                name="companyName"
                value={values.companyName}
                placeholder="Enter your company name"
                onChange={formik.handleChange("companyName")}
                error={touched.companyName && errors.companyName}
              />
            </div>
            <div className="form-field-container">
              <TextInput
                label="Phone"
                name="phone"
                value={values.phone}
                placeholder="Add your phone number"
                onChange={formik.handleChange("phone")}
                error={touched.phone && errors.phone}
              />
            </div>
            <div className="form-field-container">
              <SelectInput
                label="Location"
                name="location"
                value={values.location}
                placeholder="Select your country"
                onChange={formik.handleChange}
                error={touched.location && errors.location}
                options={locations}
              />
            </div>
            <div className="form-field-container">
              <TextInput
                label="Website"
                name="website"
                value={values.website}
                placeholder="Enter your website"
                onChange={formik.handleChange("website")}
                error={touched.website && errors.website}
              />
            </div>
            <div className="form-field-container">
              <TextInput
                label="Address"
                name="address"
                value={values.address}
                placeholder="Enter your address to recieve deliveries"
                onChange={formik.handleChange("address")}
                error={touched.address && errors.address}
              />
            </div>
            <div className="form-field-container">
              <TextInput
                label="Bio"
                name="bio"
                rows={5}
                value={values.bio}
                placeholder="Tell us about yourself"
                onChange={formik.handleChange("bio")}
                error={touched.bio && errors.bio}
                isMulti={true}
              />
            </div>
          </div>
          <LoadingButton
            type="submit"
            label="Update Profile"
            theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
            isLoading={isLoading}
            isDisabled={!dirty}
          />
        </form>
      </div>
    </div>
  )
}

export default Profile
