import React from "react"
import EmptySearchImage from "../../../assets/images/empty-search.svg"
import "./styles.scss"

const EmptyView = () => {
  return (
    <div className="find-influencers-empty-view">
      <div className="image-container">
        <img src={EmptySearchImage} alt="" className="image" />
      </div>
      <div className="heading">It looks like your search yielded no results </div>
      <div className="paragraph">Try adjusting your filters or expanding your criteria to discover more influencers</div>
    </div>
  )
}

export default EmptyView
