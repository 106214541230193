import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: null,
  token: null,
  credits: null,
  redirectUrl: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setCredits: (state, action) => {
      state.credits = action.payload
    },
    setToken: (state, action) => {
      state.token = action.payload
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload
    },
    setLogout: (state) => {
      state.user = null
      state.token = null
      state.credits = null
    },
  },
})

export const { setUser, setToken, setCredits, setRedirectUrl, setLogout } = authSlice.actions

export default authSlice.reducer
