import React from "react"
import GenericSelect from "../../Common/GenericSelect"
import { sortingOptions } from "../../../constants"
import Skeleton from "@mui/material/Skeleton"
import "./styles.scss"

const SortCards = ({ isLoading, state, setState, cardsPresent, filtersApplied }) => {
  const formatSortingFieldLabel = (option, { context }) => {
    return context === "value" ? `Sort By: ${option.label}` : option.label
  }

  return (
    <div className="sort-cards-container">
      {isLoading && <Skeleton variant="rounded" width={260} height={24} />}
      {!isLoading && cardsPresent && filtersApplied && (
        <GenericSelect
          options={sortingOptions}
          value={state}
          handleChange={setState}
          formatOptionLabel={formatSortingFieldLabel}
          placeholder="Sort By..."
          isSearchable={false}
        />
      )}
    </div>
  )
}

export default SortCards
