export const platforms = ["instagram", "youtube", "tiktok"]

export const platformsContent = [
  {
    name: "Instagram",
    contentTypes: ["Post", "Story", "Reel"],
  },
  {
    name: "TikTok",
    contentTypes: ["Video", "Story"],
  },
  {
    name: "Youtube",
    contentTypes: ["Video", "Short"],
  },
]

export const tagColors = ["#D9D9D9", "#51B8D7", "#79E195", "#F2993E", "#5DB180", "#B073D5"]

export const saveFilterAllowedAccounts = ["umair.khalid@brainxtech.com", "aaron@hiveinfluence.io", "sheraz.khalid@brainxtech.com"]

export const sortingOptions = [
  { label: "Followers", value: "followers" },
  { label: "Engagements", value: "engagements" },
  { label: "Engagement Rate", value: "engagementRate" },
]

export const numberRange = {
  from: [
    { label: "1k", value: 1000 },
    { label: "3k", value: 3000 },
    { label: "5k", value: 5000 },
    { label: "10k", value: 10000 },
    { label: "15k", value: 15000 },
    { label: "20k", value: 20000 },
    { label: "25k", value: 25000 },
    { label: "35k", value: 35000 },
    { label: "50k", value: 50000 },
    { label: "75k", value: 75000 },
    { label: "100k", value: 100000 },
    { label: "125k", value: 125000 },
    { label: "150k", value: 150000 },
    { label: "175k", value: 175000 },
    { label: "200k", value: 200000 },
    { label: "250k", value: 250000 },
    { label: "300k", value: 300000 },
    { label: "350k", value: 350000 },
    { label: "500k", value: 500000 },
    { label: "1M", value: 1000000 },
    { label: "2M", value: 2000000 },
  ],
  to: [
    { label: "1k", value: 1000 },
    { label: "3k", value: 3000 },
    { label: "5k", value: 5000 },
    { label: "10k", value: 10000 },
    { label: "15k", value: 15000 },
    { label: "20k", value: 20000 },
    { label: "25k", value: 25000 },
    { label: "35k", value: 35000 },
    { label: "50k", value: 50000 },
    { label: "75k", value: 75000 },
    { label: "100k", value: 100000 },
    { label: "125k", value: 125000 },
    { label: "150k", value: 150000 },
    { label: "175k", value: 175000 },
    { label: "200k", value: 200000 },
    { label: "250k", value: 250000 },
    { label: "300k", value: 300000 },
    { label: "350k", value: 350000 },
    { label: "500k", value: 500000 },
    { label: "1M", value: 1000000 },
    { label: "2M", value: 2000000 },
    { label: "3M+", value: 700000000 },
  ],
}

export const ageRange = {
  from: [
    { label: "18", value: 18 },
    { label: "25", value: 25 },
    { label: "35", value: 35 },
    { label: "45", value: 45 },
  ],
  to: [
    { label: "25", value: 25 },
    { label: "35", value: 35 },
    { label: "45", value: 45 },
  ],
}

export const audienceGenderOptions = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
]

export const influencerGenderOptions = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
  { label: "Both", value: "KNOWN" },
  { label: "Other", value: "UNKNOWN" },
]

export const engagementOptions = [
  { label: "≥1%", value: 0.01 },
  { label: "≥2%", value: 0.02 },
  { label: "≥3%", value: 0.03 },
  { label: "≥4%", value: 0.04 },
  { label: "≥5%", value: 0.05 },
  { label: "≥6%", value: 0.06 },
  { label: "≥7%", value: 0.07 },
  { label: "≥8%", value: 0.08 },
  { label: "≥9%", value: 0.09 },
  { label: "≥10%", value: 0.1 },
]

export const lastPostOptions = [
  { label: "1 month", value: 30 },
  { label: "2 months", value: 60 },
  { label: "3 months", value: 90 },
]

export const fakeFollowersOptions = [
  { label: "≤10%", value: 0.9 },
  { label: "≤20%", value: 0.8 },
  { label: "≤30%", value: 0.7 },
  { label: "≤50%", value: 0.5 },
  { label: "≤75%", value: 0.25 },
]

export const accountOptions = [
  { label: "Regular", value: 1 },
  { label: "Business", value: 2 },
  { label: "Creator", value: 3 },
]

export const contactOptions = [
  { label: "BBM", value: "bbm" },
  { label: "Email", value: "email" },
  { label: "Facebook", value: "facebook" },
  { label: "Instagram", value: "instagram" },
  { label: "KakaoTalk", value: "kakao" },
  { label: "kik", value: "kik" },
  { label: "LINE ID", value: "lineid" },
  { label: "Linktree", value: "linktree" },
  { label: "Pinterest", value: "pinterest" },
  { label: "Sarahah", value: "sarahah" },
  { label: "Sayat.Me", value: "sayat" },
  { label: "Skype", value: "skype" },
  { label: "Skype", value: "skype" },
  { label: "Snapchat", value: "snapchat" },
  { label: "TikTok", value: "tiktok" },
  { label: "Tumblr", value: "tumblr" },
  { label: "Twitch", value: "twitchtv" },
  { label: "Twitter", value: "twitter" },
  { label: "VKontakte", value: "vk" },
  { label: "WeChat", value: "wechat" },
  { label: "YouTube", value: "youtube" },
]

export const interestsOptions = [
  { label: "Television & Film", value: 1 },
  { label: "Music", value: 3 },
  { label: "Shopping & Retail", value: 7 },
  { label: "Coffee, Tea & Beverages", value: 9 },
  { label: "Camera & Photography", value: 11 },
  { label: "Clothes, Shoes, Handbags & Accessories", value: 13 },
  { label: "Beer, Wine & Spirits", value: 19 },
  { label: "Sports", value: 21 },
  { label: "Electronics & Computers", value: 25 },
  { label: "Gaming", value: 30 },
  { label: "Activewear", value: 33 },
  { label: "Art & Design", value: 36 },
  { label: "Travel, Tourism & Aviation", value: 43 },
  { label: "Business & Careers", value: 58 },
  { label: "Beauty & Cosmetics", value: 80 },
  { label: "Healthcare & Medicine", value: 100 },
  { label: "Jewellery & Watches", value: 130 },
  { label: "Restaurants, Food & Grocery", value: 139 },
  { label: "Toys, Children & Baby", value: 190 },
  { label: "Fitness & Yoga", value: 196 },
  { label: "Wedding", value: 291 },
  { label: "Tobacco & Smoking", value: 405 },
  { label: "Pets", value: 673 },
  { label: "Healthy Lifestyle", value: 798 },
  { label: "Luxury Goods", value: 1500 },
  { label: "Home Decor, Furniture & Garden", value: 1560 },
  { label: "Friends, Family & Relationships", value: 1708 },
  { label: "Cars & Motorbikes", value: 1826 },
]

export const growthRateOptions = [
  { label: "1 month", value: "i1month" },
  { label: "2 months", value: "i2months" },
  { label: "3 months", value: "i3months" },
  { label: "4 months", value: "i4months" },
  { label: "5 months", value: "i5months" },
  { label: "6 months", value: "i6months" },
]

export const audienceAgeOptions = [
  { label: "13-17", value: "13-17" },
  { label: "18-24", value: "18-24" },
  { label: "25-34", value: "25-34" },
  { label: "35-44", value: "35-44" },
  { label: "45-64", value: "45-64" },
  { label: "65-", value: "65-" },
]

export const weightOptions = [
  { label: "≥5%", value: 0.05 },
  { label: "≥10%", value: 0.1 },
  { label: "≥15%", value: 0.15 },
  { label: "≥20%", value: 0.2 },
  { label: "≥25%", value: 0.25 },
  { label: "≥30%", value: 0.3 },
  { label: "≥35%", value: 0.35 },
  { label: "≥40%", value: 0.4 },
  { label: "≥45%", value: 0.45 },
  { label: "≥50%", value: 0.5 },
  { label: "≥55%", value: 0.55 },
  { label: "≥60%", value: 0.6 },
  { label: "≥65%", value: 0.65 },
  { label: "≥70%", value: 0.7 },
  { label: "≥75%", value: 0.75 },
  { label: "≥80%", value: 0.8 },
  { label: "≥85%", value: 0.85 },
  { label: "≥90%", value: 0.9 },
  { label: "≥95%", value: 0.95 },
]

export const extendedWeightOptions = [
  { label: ">10%", value: 0.1 },
  { label: ">20%", value: 0.2 },
  { label: ">30%", value: 0.3 },
  { label: ">40%", value: 0.4 },
  { label: ">50%", value: 0.5 },
  { label: ">60%", value: 0.6 },
  { label: ">70%", value: 0.7 },
  { label: ">80%", value: 0.8 },
  { label: ">90%", value: 0.9 },
  { label: ">100%", value: 1 },
  { label: ">110%", value: 1.1 },
  { label: ">120%", value: 1.2 },
  { label: ">130%", value: 1.3 },
  { label: ">140%", value: 1.4 },
  { label: ">150%", value: 1.5 },
  { label: ">160%", value: 1.6 },
  { label: ">170%", value: 1.7 },
  { label: ">180%", value: 1.8 },
  { label: ">190%", value: 1.9 },
  { label: ">200%", value: 2 },
  { label: ">210%", value: 2.1 },
  { label: ">220%", value: 2.2 },
  { label: ">230%", value: 2.3 },
  { label: ">240%", value: 2.4 },
  { label: ">250%", value: 2.5 },
  { label: ">260%", value: 2.6 },
  { label: ">270%", value: 2.7 },
  { label: ">280%", value: 2.8 },
  { label: ">290%", value: 2.9 },
  { label: ">300%", value: 3 },
  { label: "<10%", value: -0.1 },
  { label: "<20%", value: -0.2 },
  { label: "<30%", value: -0.3 },
  { label: "<40%", value: -0.4 },
  { label: "<50%", value: -0.5 },
  { label: "<60%", value: -0.6 },
  { label: "<70%", value: -0.7 },
  { label: "<80%", value: -0.8 },
  { label: "<90%", value: -0.9 },
  { label: "<100%", value: -1.0 },
  { label: "<110%", value: -1.1 },
  { label: "<120%", value: -1.2 },
  { label: "<130%", value: -1.3 },
  { label: "<140%", value: -1.4 },
  { label: "<150%", value: -1.5 },
  { label: "<160%", value: -1.6 },
  { label: "<170%", value: -1.7 },
  { label: "<180%", value: -1.8 },
  { label: "<190%", value: -1.9 },
  { label: "<200%", value: -2.0 },
  { label: "<210%", value: -2.1 },
  { label: "<220%", value: -2.2 },
  { label: "<230%", value: -2.3 },
  { label: "<240%", value: -2.4 },
  { label: "<250%", value: -2.5 },
  { label: "<260%", value: -2.6 },
  { label: "<270%", value: -2.7 },
  { label: "<280%", value: -2.8 },
  { label: "<290%", value: -2.9 },
  { label: "<300%", value: -3.0 },
]

export const influencerTypeWithLocation = [
  { type: "Yoga", location: "Bali" },
  { type: "Sports", location: "Melbourne" },
  { type: "Fashion", location: "Paris" },
  { type: "Model", location: "New York" },
  { type: "Tech", location: "Silicon Valley" },
  { type: "Music", location: "Nashville" },
  { type: "Art", location: "Barcelona" },
  { type: "Beauty", location: "Los Angeles" },
  { type: "Business", location: "Hong Kong" },
  { type: "Education", location: "Cambridge" },
  { type: "Health", location: "San Diego" },
  { type: "Gaming", location: "Seoul" },
]

export const currencies = [
  {
    label: "US Dollar ($)",
    value: "USD",
  },
  {
    label: "Canadian Dollar (CA$)",
    value: "CAD",
  },
  {
    label: "Euro (€)",
    value: "EUR",
  },
  {
    label: "United Arab Emirates Dirham (AED)",
    value: "AED",
  },
  {
    label: "Afghan Afghani (Af)",
    value: "AFN",
  },
  {
    label: "Albanian Lek (ALL)",
    value: "ALL",
  },
  {
    label: "Armenian Dram (AMD)",
    value: "AMD",
  },
  {
    label: "Argentine Peso (AR$)",
    value: "ARS",
  },
  {
    label: "Australian Dollar (AU$)",
    value: "AUD",
  },
  {
    label: "Azerbaijani Manat (man.)",
    value: "AZN",
  },
  {
    label: "Bosnia-Herzegovina Convertible Mark (KM)",
    value: "BAM",
  },
  {
    label: "Bangladeshi Taka (Tk)",
    value: "BDT",
  },
  {
    label: "Bulgarian Lev (BGN)",
    value: "BGN",
  },
  {
    label: "Bahraini Dinar (BD)",
    value: "BHD",
  },
  {
    label: "Burundian Franc (FBu)",
    value: "BIF",
  },
  {
    label: "Brunei Dollar (BN$)",
    value: "BND",
  },
  {
    label: "Bolivian Boliviano (Bs)",
    value: "BOB",
  },
  {
    label: "Brazilian Real (R$)",
    value: "BRL",
  },
  {
    label: "Botswanan Pula (BWP)",
    value: "BWP",
  },
  {
    label: "Belarusian Ruble (Br)",
    value: "BYN",
  },
  {
    label: "Belize Dollar (BZ$)",
    value: "BZD",
  },
  {
    label: "Congolese Franc (CDF)",
    value: "CDF",
  },
  {
    label: "Swiss Franc (CHF)",
    value: "CHF",
  },
  {
    label: "Chilean Peso (CL$)",
    value: "CLP",
  },
  {
    label: "Chinese Yuan (CN¥)",
    value: "CNY",
  },
  {
    label: "Colombian Peso (CO$)",
    value: "COP",
  },
  {
    label: "Costa Rican Colón (₡)",
    value: "CRC",
  },
  {
    label: "Cape Verdean Escudo (CV$)",
    value: "CVE",
  },
  {
    label: "Czech Republic Koruna (Kč)",
    value: "CZK",
  },
  {
    label: "Djiboutian Franc (Fdj)",
    value: "DJF",
  },
  {
    label: "Danish Krone (Dkr)",
    value: "DKK",
  },
  {
    label: "Dominican Peso (RD$)",
    value: "DOP",
  },
  {
    label: "Algerian Dinar (DA)",
    value: "DZD",
  },
  {
    label: "Estonian Kroon (Ekr)",
    value: "EEK",
  },
  {
    label: "Egyptian Pound (EGP)",
    value: "EGP",
  },
  {
    label: "Eritrean Nakfa (Nfk)",
    value: "ERN",
  },
  {
    label: "Ethiopian Birr (Br)",
    value: "ETB",
  },
  {
    label: "British Pound Sterling (£)",
    value: "GBP",
  },
  {
    label: "Georgian Lari (GEL)",
    value: "GEL",
  },
  {
    label: "Ghanaian Cedi (GH₵)",
    value: "GHS",
  },
  {
    label: "Guinean Franc (FG)",
    value: "GNF",
  },
  {
    label: "Guatemalan Quetzal (GTQ)",
    value: "GTQ",
  },
  {
    label: "Hong Kong Dollar (HK$)",
    value: "HKD",
  },
  {
    label: "Honduran Lempira (HNL)",
    value: "HNL",
  },
  {
    label: "Croatian Kuna (kn)",
    value: "HRK",
  },
  {
    label: "Hungarian Forint (Ft)",
    value: "HUF",
  },
  {
    label: "Indonesian Rupiah (Rp)",
    value: "IDR",
  },
  {
    label: "Israeli New Sheqel (₪)",
    value: "ILS",
  },
  {
    label: "Indian Rupee (Rs)",
    value: "INR",
  },
  {
    label: "Iraqi Dinar (IQD)",
    value: "IQD",
  },
  {
    label: "Iranian Rial (IRR)",
    value: "IRR",
  },
  {
    label: "Icelandic Króna (Ikr)",
    value: "ISK",
  },
  {
    label: "Jamaican Dollar (J$)",
    value: "JMD",
  },
  {
    label: "Jordanian Dinar (JD)",
    value: "JOD",
  },
  {
    label: "Japanese Yen (¥)",
    value: "JPY",
  },
  {
    label: "Kenyan Shilling (Ksh)",
    value: "KES",
  },
  {
    label: "Cambodian Riel (KHR)",
    value: "KHR",
  },
  {
    label: "Comorian Franc (CF)",
    value: "KMF",
  },
  {
    label: "South Korean Won (₩)",
    value: "KRW",
  },
  {
    label: "Kuwaiti Dinar (KD)",
    value: "KWD",
  },
  {
    label: "Kazakhstani Tenge (KZT)",
    value: "KZT",
  },
  {
    label: "Lebanese Pound (L.L.)",
    value: "LBP",
  },
  {
    label: "Sri Lankan Rupee (SLRs)",
    value: "LKR",
  },
  {
    label: "Lithuanian Litas (Lt)",
    value: "LTL",
  },
  {
    label: "Latvian Lats (Ls)",
    value: "LVL",
  },
  {
    label: "Libyan Dinar (LD)",
    value: "LYD",
  },
  {
    label: "Moroccan Dirham (MAD)",
    value: "MAD",
  },
  {
    label: "Moldovan Leu (MDL)",
    value: "MDL",
  },
  {
    label: "Malagasy Ariary (MGA)",
    value: "MGA",
  },
  {
    label: "Macedonian Denar (MKD)",
    value: "MKD",
  },
  {
    label: "Myanma Kyat (MMK)",
    value: "MMK",
  },
  {
    label: "Macanese Pataca (MOP$)",
    value: "MOP",
  },
  {
    label: "Mauritian Rupee (MURs)",
    value: "MUR",
  },
  {
    label: "Mexican Peso (MX$)",
    value: "MXN",
  },
  {
    label: "Malaysian Ringgit (RM)",
    value: "MYR",
  },
  {
    label: "Mozambican Metical (MTn)",
    value: "MZN",
  },
  {
    label: "Namibian Dollar (N$)",
    value: "NAD",
  },
  {
    label: "Nigerian Naira (₦)",
    value: "NGN",
  },
  {
    label: "Nicaraguan Córdoba (C$)",
    value: "NIO",
  },
  {
    label: "Norwegian Krone (Nkr)",
    value: "NOK",
  },
  {
    label: "Nepalese Rupee (NPRs)",
    value: "NPR",
  },
  {
    label: "New Zealand Dollar (NZ$)",
    value: "NZD",
  },
  {
    label: "Omani Rial (OMR)",
    value: "OMR",
  },
  {
    label: "Panamanian Balboa (B/.)",
    value: "PAB",
  },
  {
    label: "Peruvian Nuevo Sol (S/.)",
    value: "PEN",
  },
  {
    label: "Philippine Peso (₱)",
    value: "PHP",
  },
  {
    label: "Pakistani Rupee (PKRs)",
    value: "PKR",
  },
  {
    label: "Polish Zloty (zł)",
    value: "PLN",
  },
  {
    label: "Paraguayan Guarani (₲)",
    value: "PYG",
  },
  {
    label: "Qatari Rial (QR)",
    value: "QAR",
  },
  {
    label: "Romanian Leu (RON)",
    value: "RON",
  },
  {
    label: "Serbian Dinar (din.)",
    value: "RSD",
  },
  {
    label: "Russian Ruble (RUB)",
    value: "RUB",
  },
  {
    label: "Rwandan Franc (RWF)",
    value: "RWF",
  },
  {
    label: "Saudi Riyal (SR)",
    value: "SAR",
  },
  {
    label: "Sudanese Pound (SDG)",
    value: "SDG",
  },
  {
    label: "Swedish Krona (Skr)",
    value: "SEK",
  },
  {
    label: "Singapore Dollar (S$)",
    value: "SGD",
  },
  {
    label: "Somali Shilling (Ssh)",
    value: "SOS",
  },
  {
    label: "Syrian Pound (SY£)",
    value: "SYP",
  },
  {
    label: "Thai Baht (฿)",
    value: "THB",
  },
  {
    label: "Tunisian Dinar (DT)",
    value: "TND",
  },
  {
    label: "Tongan Paʻanga (T$)",
    value: "TOP",
  },
  {
    label: "Turkish Lira (TL)",
    value: "TRY",
  },
  {
    label: "Trinidad and Tobago Dollar (TT$)",
    value: "TTD",
  },
  {
    label: "New Taiwan Dollar (NT$)",
    value: "TWD",
  },
  {
    label: "Tanzanian Shilling (TSh)",
    value: "TZS",
  },
  {
    label: "Ukrainian Hryvnia (₴)",
    value: "UAH",
  },
  {
    label: "Ugandan Shilling (USh)",
    value: "UGX",
  },
  {
    label: "Uruguayan Peso ($U)",
    value: "UYU",
  },
  {
    label: "Uzbekistan Som (UZS)",
    value: "UZS",
  },
  {
    label: "Venezuelan Bolívar (Bs.F.)",
    value: "VEF",
  },
  {
    label: "Vietnamese Dong (₫)",
    value: "VND",
  },
  {
    label: "CFA Franc BEAC (FCFA)",
    value: "XAF",
  },
  {
    label: "CFA Franc BCEAO (CFA)",
    value: "XOF",
  },
  {
    label: "Yemeni Rial (YR)",
    value: "YER",
  },
  {
    label: "South African Rand (R)",
    value: "ZAR",
  },
  {
    label: "Zambian Kwacha (ZK)",
    value: "ZMK",
  },
  {
    label: "Zimbabwean Dollar (ZWL$)",
    value: "ZWL",
  },
]

export const locations = [
  { label: "Afghanistan", value: "Afghanistan" },
  { label: "Åland Islands", value: "Åland Islands" },
  { label: "Albania", value: "Albania" },
  { label: "Algeria", value: "Algeria" },
  { label: "American Samoa", value: "American Samoa" },
  { label: "Andorra", value: "Andorra" },
  { label: "Angola", value: "Angola" },
  { label: "Anguilla", value: "Anguilla" },
  { label: "Antarctica", value: "Antarctica" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
  { label: "Argentina", value: "Argentina" },
  { label: "Armenia", value: "Armenia" },
  { label: "Aruba", value: "Aruba" },
  { label: "Australia", value: "Australia" },
  { label: "Austria", value: "Austria" },
  { label: "Azerbaijan", value: "Azerbaijan" },
  { label: "Bahamas", value: "Bahamas" },
  { label: "Bahrain", value: "Bahrain" },
  { label: "Bangladesh", value: "Bangladesh" },
  { label: "Barbados", value: "Barbados" },
  { label: "Belarus", value: "Belarus" },
  { label: "Belgium", value: "Belgium" },
  { label: "Belize", value: "Belize" },
  { label: "Benin", value: "Benin" },
  { label: "Bermuda", value: "Bermuda" },
  { label: "Bhutan", value: "Bhutan" },
  { label: "Bolivia", value: "Bolivia" },
  { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
  { label: "Botswana", value: "Botswana" },
  { label: "Bouvet Island", value: "Bouvet Island" },
  { label: "Brazil", value: "Brazil" },
  { label: "British Indian Ocean Territory", value: "British Indian Ocean Territory" },
  { label: "Brunei Darussalam", value: "Brunei Darussalam" },
  { label: "Bulgaria", value: "Bulgaria" },
  { label: "Burkina Faso", value: "Burkina Faso" },
  { label: "Burundi", value: "Burundi" },
  { label: "Cambodia", value: "Cambodia" },
  { label: "Cameroon", value: "Cameroon" },
  { label: "Canada", value: "Canada" },
  { label: "Cape Verde", value: "Cape Verde" },
  { label: "Cayman Islands", value: "Cayman Islands" },
  { label: "Central African Republic", value: "Central African Republic" },
  { label: "Chad", value: "Chad" },
  { label: "Chile", value: "Chile" },
  { label: "China", value: "China" },
  { label: "Christmas Island", value: "Christmas Island" },
  { label: "Cocos (Keeling) Islands", value: "Cocos (Keeling) Islands" },
  { label: "Colombia", value: "Colombia" },
  { label: "Comoros", value: "Comoros" },
  { label: "Congo", value: "Congo" },
  { label: "Congo, The Democratic Republic of the", value: "Congo, The Democratic Republic of the" },
  { label: "Cook Islands", value: "Cook Islands" },
  { label: "Costa Rica", value: "Costa Rica" },
  { label: "Cote D'Ivoire", value: "Cote D'Ivoire" },
  { label: "Croatia", value: "Croatia" },
  { label: "Cuba", value: "Cuba" },
  { label: "Cyprus", value: "Cyprus" },
  { label: "Czech Republic", value: "Czech Republic" },
  { label: "Denmark", value: "Denmark" },
  { label: "Djibouti", value: "Djibouti" },
  { label: "Dominica", value: "Dominica" },
  { label: "Dominican Republic", value: "Dominican Republic" },
  { label: "Ecuador", value: "Ecuador" },
  { label: "Egypt", value: "Egypt" },
  { label: "El Salvador", value: "El Salvador" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea" },
  { label: "Eritrea", value: "Eritrea" },
  { label: "Estonia", value: "Estonia" },
  { label: "Ethiopia", value: "Ethiopia" },
  { label: "Falkland Islands (Malvinas)", value: "Falkland Islands (Malvinas)" },
  { label: "Faroe Islands", value: "Faroe Islands" },
  { label: "Fiji", value: "Fiji" },
  { label: "Finland", value: "Finland" },
  { label: "France", value: "France" },
  { label: "French Guiana", value: "French Guiana" },
  { label: "French Polynesia", value: "French Polynesia" },
  { label: "French Southern Territories", value: "French Southern Territories" },
  { label: "Gabon", value: "Gabon" },
  { label: "Gambia", value: "Gambia" },
  { label: "Georgia", value: "Georgia" },
  { label: "Germany", value: "Germany" },
  { label: "Ghana", value: "Ghana" },
  { label: "Gibraltar", value: "Gibraltar" },
  { label: "Greece", value: "Greece" },
  { label: "Greenland", value: "Greenland" },
  { label: "Grenada", value: "Grenada" },
  { label: "Guadeloupe", value: "Guadeloupe" },
  { label: "Guam", value: "Guam" },
  { label: "Guatemala", value: "Guatemala" },
  { label: "Guernsey", value: "Guernsey" },
  { label: "Guinea", value: "Guinea" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau" },
  { label: "Guyana", value: "Guyana" },
  { label: "Haiti", value: "Haiti" },
  { label: "Heard Island and Mcdonald Islands", value: "Heard Island and Mcdonald Islands" },
  { label: "Holy See (Vatican City State)", value: "Holy See (Vatican City State)" },
  { label: "Honduras", value: "Honduras" },
  { label: "Hong Kong", value: "Hong Kong" },
  { label: "Hungary", value: "Hungary" },
  { label: "Iceland", value: "Iceland" },
  { label: "India", value: "India" },
  { label: "Indonesia", value: "Indonesia" },
  { label: "Iran, Islamic Republic of", value: "Iran, Islamic Republic of" },
  { label: "Iraq", value: "Iraq" },
  { label: "Ireland", value: "Ireland" },
  { label: "Isle of Man", value: "Isle of Man" },
  { label: "Israel", value: "Israel" },
  { label: "Italy", value: "Italy" },
  { label: "Jamaica", value: "Jamaica" },
  { label: "Japan", value: "Japan" },
  { label: "Jersey", value: "Jersey" },
  { label: "Jordan", value: "Jordan" },
  { label: "Kazakhstan", value: "Kazakhstan" },
  { label: "Kenya", value: "Kenya" },
  { label: "Kiribati", value: "Kiribati" },
  { label: "Korea, Democratic People'S Republic of", value: "Korea, Democratic People'S Republic of" },
  { label: "Korea, Republic of", value: "Korea, Republic of" },
  { label: "Kuwait", value: "Kuwait" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan" },
  { label: "Lao People'S Democratic Republic", value: "Lao People'S Democratic Republic" },
  { label: "Latvia", value: "Latvia" },
  { label: "Lebanon", value: "Lebanon" },
  { label: "Lesotho", value: "Lesotho" },
  { label: "Liberia", value: "Liberia" },
  { label: "Libyan Arab Jamahiriya", value: "Libyan Arab Jamahiriya" },
  { label: "Liechtenstein", value: "Liechtenstein" },
  { label: "Lithuania", value: "Lithuania" },
  { label: "Luxembourg", value: "Luxembourg" },
  { label: "Macao", value: "Macao" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "Macedonia, The Former Yugoslav Republic of" },
  { label: "Madagascar", value: "Madagascar" },
  { label: "Malawi", value: "Malawi" },
  { label: "Malaysia", value: "Malaysia" },
  { label: "Maldives", value: "Maldives" },
  { label: "Mali", value: "Mali" },
  { label: "Malta", value: "Malta" },
  { label: "Marshall Islands", value: "Marshall Islands" },
  { label: "Martinique", value: "Martinique" },
  { label: "Mauritania", value: "Mauritania" },
  { label: "Mauritius", value: "Mauritius" },
  { label: "Mayotte", value: "Mayotte" },
  { label: "Mexico", value: "Mexico" },
  { label: "Micronesia, Federated States of", value: "Micronesia, Federated States of" },
  { label: "Moldova, Republic of", value: "Moldova, Republic of" },
  { label: "Monaco", value: "Monaco" },
  { label: "Mongolia", value: "Mongolia" },
  { label: "Montserrat", value: "Montserrat" },
  { label: "Morocco", value: "Morocco" },
  { label: "Mozambique", value: "Mozambique" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Namibia", value: "Namibia" },
  { label: "Nauru", value: "Nauru" },
  { label: "Nepal", value: "Nepal" },
  { label: "Netherlands", value: "Netherlands" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles" },
  { label: "New Caledonia", value: "New Caledonia" },
  { label: "New Zealand", value: "New Zealand" },
  { label: "Nicaragua", value: "Nicaragua" },
  { label: "Niger", value: "Niger" },
  { label: "Nigeria", value: "Nigeria" },
  { label: "Niue", value: "Niue" },
  { label: "Norfolk Island", value: "Norfolk Island" },
  { label: "Northern Mariana Islands", value: "Northern Mariana Islands" },
  { label: "Norway", value: "Norway" },
  { label: "Oman", value: "Oman" },
  { label: "Pakistan", value: "Pakistan" },
  { label: "Palau", value: "Palau" },
  { label: "Palestinian Territory, Occupied", value: "Palestinian Territory, Occupied" },
  { label: "Panama", value: "Panama" },
  { label: "Papua New Guinea", value: "Papua New Guinea" },
  { label: "Paraguay", value: "Paraguay" },
  { label: "Peru", value: "Peru" },
  { label: "Philippines", value: "Philippines" },
  { label: "Pitcairn", value: "Pitcairn" },
  { label: "Poland", value: "Poland" },
  { label: "Portugal", value: "Portugal" },
  { label: "Puerto Rico", value: "Puerto Rico" },
  { label: "Qatar", value: "Qatar" },
  { label: "Reunion", value: "Reunion" },
  { label: "Romania", value: "Romania" },
  { label: "Russian Federation", value: "Russian Federation" },
  { label: "RWANDA", value: "RWANDA" },
  { label: "Saint Helena", value: "Saint Helena" },
  { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
  { label: "Saint Lucia", value: "Saint Lucia" },
  { label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
  { label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines" },
  { label: "Samoa", value: "Samoa" },
  { label: "San Marino", value: "San Marino" },
  { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "Senegal", value: "Senegal" },
  { label: "Serbia and Montenegro", value: "Serbia and Montenegro" },
  { label: "Seychelles", value: "Seychelles" },
  { label: "Sierra Leone", value: "Sierra Leone" },
  { label: "Singapore", value: "Singapore" },
  { label: "Slovakia", value: "Slovakia" },
  { label: "Slovenia", value: "Slovenia" },
  { label: "Solomon Islands", value: "Solomon Islands" },
  { label: "Somalia", value: "Somalia" },
  { label: "South Africa", value: "South Africa" },
  { label: "South Georgia and the South Sandwich Islands", value: "South Georgia and the South Sandwich Islands" },
  { label: "Spain", value: "Spain" },
  { label: "Sri Lanka", value: "Sri Lanka" },
  { label: "Sudan", value: "Sudan" },
  { label: "Suriname", value: "Suriname" },
  { label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
  { label: "Swaziland", value: "Swaziland" },
  { label: "Sweden", value: "Sweden" },
  { label: "Switzerland", value: "Switzerland" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic" },
  { label: "Taiwan, Province ofChina", value: "Taiwan, Province of China" },
  { label: "Tajikistan", value: "Tajikistan" },
  { label: "Tanzania, United Republic of", value: "Tanzania, United Republic of" },
  { label: "Thailand", value: "Thailand" },
  { label: "Timor-Leste", value: "Timor-Leste" },
  { label: "Togo", value: "Togo" },
  { label: "Tokelau", value: "Tokelau" },
  { label: "Tonga", value: "Tonga" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
  { label: "Tunisia", value: "Tunisia" },
  { label: "Turkey", value: "Turkey" },
  { label: "Turkmenistan", value: "Turkmenistan" },
  { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
  { label: "Tuvalu", value: "Tuvalu" },
  { label: "Uganda", value: "Uganda" },
  { label: "Ukraine", value: "Ukraine" },
  { label: "United Arab Emirates", value: "United Arab Emirates" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "United States", value: "United States" },
  { label: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands" },
  { label: "Uruguay", value: "Uruguay" },
  { label: "Uzbekistan", value: "Uzbekistan" },
  { label: "Vanuatu", value: "Vanuatu" },
  { label: "Venezuela", value: "Venezuela" },
  { label: "Viet Nam", value: "Viet Nam" },
  { label: "Virgin Islands, British", value: "Virgin Islands, British" },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S." },
  { label: "Wallis and Futuna", value: "Wallis and Futuna" },
  { label: "Western Sahara", value: "Western Sahara" },
  { label: "Yemen", value: "Yemen" },
  { label: "Zambia", value: "Zambia" },
  { label: "Zimbabwe", value: "Zimbabwe" },
]
