import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import notify from "../../../../components/Common/Toast";
import usePaymentMethodService from "../../../../services/usePaymentMethodService";
import { useState } from "react";
import "./style.scss";
const PaymentSetupForm = ({
  setStripeModalOpen,
  redirectionUrl,
  submitBtnText = "",
  reloading = "",
  setReloading = "",
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [elementsLoaded, setElementsLoaded] = useState(false);
  const paymentMethodService = usePaymentMethodService();

  const handleElementsLoaded = () => {
    setElementsLoaded(true);
  };

  const handleConfirmSetup = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      document
        .getElementById("paymentMethodSpinner")
        .classList.remove("d-none");
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: redirectionUrl
            ? redirectionUrl
            : `${window.location.href.split("?")[0]}?activeTab=payment`,
        },
      });
      if (result.error) {
        document.getElementById("paymentMethodSpinner").classList.add("d-none");
        notify.error(result.error.message);
        if (result.error.code === "setup_intent_unexpected_state") {
          let response = paymentMethodService.deleteIntents();
          if (reloading && reloading === true) {
            setReloading(false);
          } else if (reloading && reloading === false) {
            setReloading(true);
          }
          setStripeModalOpen(false);
        }
      } else {
        setStripeModalOpen(false);
        notify.success("Payment Method added successfully");
      }
    } catch (error) {
      notify.error(error);
    }
  };

  return (
    <div className="pe-4 pt-4 w-100">
      <PaymentElement onReady={handleElementsLoaded} />
      <button
        className={elementsLoaded ? "submit-button" : "submit-button-disabled"}
        onClick={handleConfirmSetup}
        disabled={!elementsLoaded}
      >
        <div
          class="spinner-border spinner-scale me-2 d-none text-light"
          role="status"
          id="paymentMethodSpinner"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        {submitBtnText || "Add Card"}
      </button>
    </div>
  );
};
export default PaymentSetupForm;
