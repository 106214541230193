import React, { useState } from "react"
import { Img } from "react-image"
import { Button, IconDropdownMenu } from "../../Common/CommonElements"
import MenuItem from "@mui/material/MenuItem"
import Skeleton from "@mui/material/Skeleton"
import { Tooltip } from "@mui/material"
import "./styles.scss"
import TikTokIcon from "../../../assets/images/tiktok-contact.svg"
import FacebookIcon from "../../../assets/images/facebook-contact.svg"
import InstagramIcon from "../../../assets/images/instagram-contact.svg"
import TwitterIcon from "../../../assets/images/twitter-contact.svg"
import LinktreeIcon from "../../../assets/images/linktree-contact.svg"
import YouTubeIcon from "../../../assets/images/youtube-contact.svg"
import EmailIcon from "../../../assets/images/email-contact.svg"
import BoardIcon from "../../../assets/images/board.svg"
import NoteIcon from "../../../assets/images/note.svg"
import EditIcon from "../../../assets/images/edit.svg"
import RemoveIcon from "../../../assets/images/remove.svg"
import InstagramProfileIcon from "../../../assets/images/card-instagram-profile.svg"
import YoutubeProfileIcon from "../../../assets/images/card-youtube-profile.svg"
import TiktokProfileIcon from "../../../assets/images/card-tiktok-profile.svg"

const Report = ({ influencer, createNote, editNote, removeNote, handleAddCardsToBoard, openNotesModal }) => {
  const [tooltipText, setTooltipText] = useState("Click to copy the email")

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setTooltipText("Copied!")
      setTimeout(() => {
        setTooltipText("Click to copy the email")
      }, 1500)
    })
  }

  const headline = () => {
    const maxWeight = (items) => {
      if (!items || items.length === 0) return null
      return items.reduce((max, item) => (item.value > max.value ? item : max), items[0])
    }

    const audience = influencer.audiences.find((aud) => aud.type === "followers")
    if (!audience) return null

    const { countries = [], genders = [], ages = [] } = audience

    const topCountry = maxWeight(countries)
    const topGender = maxWeight(genders)
    const topAge = maxWeight(ages)

    let message = []

    if (topCountry) {
      message.push(`located in <b>${topCountry.key} (${topCountry.value}%)</b>`)
    }

    if (topGender) {
      message.push(`is <b>${topGender.key} (${topGender.value}%)</b>`)
    }

    if (topAge) {
      message.push(`between the ages of <b>${topAge.key} (${topAge.value}%)</b>`)
    }

    if (message.length === 0) {
      return null
    }

    return `Most of the audience is ${message.join(", ")}.`
  }

  const platformIcons = {
    instagram: InstagramProfileIcon,
    youtube: YoutubeProfileIcon,
    tiktok: TiktokProfileIcon,
  }

  return (
    influencer && (
      <div className="influencer-report">
        <div className="influencer-profile">
          <div className="image-container">
            <Img src={influencer.picture} className="image" loader={<Skeleton variant="rounded" width={200} height={120} />} />
            <div className="profile-button" onClick={() => window.open(influencer.profile, "_blank")}>
              <Tooltip title="Open profile" placement="top" arrow>
                <img src={platformIcons[influencer.platform]} alt="" className="icon" />
              </Tooltip>
            </div>
          </div>
          <div className="flex-container">
            <div className="fullname">{influencer.fullname}</div>
            <a href={influencer.profile} target="_blank" className="username" rel="noreferrer">
              @{influencer.username}
            </a>
            <div className="influencer-contact-details">
              {influencer.contact_details?.map((contact) => {
                switch (contact.type) {
                  case "email":
                    return (
                      <Tooltip title={tooltipText} placement="top" arrow>
                        <div className="icon-container" onClick={() => copyToClipboard(contact.value)}>
                          <img src={EmailIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "instagram":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={InstagramIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "youtube":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={YouTubeIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "tiktok":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={TikTokIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "facebook":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={FacebookIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "linktree":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={LinktreeIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  case "twitter":
                    return (
                      <Tooltip title="Open profile" placement="top" arrow>
                        <div className="icon-container" onClick={() => window.open(contact.value, "_blank")}>
                          <img src={TwitterIcon} alt="" className="icon" />
                        </div>
                      </Tooltip>
                    )
                  default:
                    return null
                }
              })}
            </div>
          </div>
        </div>
        <div className="influencer-profile-attributes">
          {[
            { key: "Location", value: influencer.location },
            { key: "Language", value: influencer.language },
            { key: "Account Type", value: influencer.account_type },
          ].map((object, index) => (
            <div key={index} className="flex-container">
              <div className="key">{object.key}</div>
              <div className="value">{object.value}</div>
            </div>
          ))}
        </div>
        <div className="influencer-headline">
          <div className="value" dangerouslySetInnerHTML={{ __html: headline() }} />
        </div>
        <div className="influencer-metrics">
          {[
            { key: "Followers", value: influencer.followers },
            { key: "Engagement Rate", value: influencer.engagement_rate },
            { key: "Avg. Reel Plays", value: influencer.average_reel_plays },
            { key: "Avg. Likes", value: influencer.average_likes },
            { key: "Avg. Comments", value: influencer.average_comments },
            { key: "Avg. Views", value: influencer.average_views },
            { key: "Post Count", value: influencer.post_count },
            { key: "Total Likes", value: influencer.total_likes },
            { key: "Total Views", value: influencer.total_views },
          ]
            .filter((object) => object.value !== null)
            .map((object, index) => (
              <div key={index} className="flex-container">
                <div className="value">{object.value}</div>
                <div className="key">{object.key}</div>
              </div>
            ))}
        </div>
        <div className="boards">
          <div className="flex-container">
            <img src={BoardIcon} className="icon" alt="" />
            <div className="heading">Boards</div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                type="button"
                label="Add to board"
                onClick={() => handleAddCardsToBoard([influencer.id])}
                theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "3px 12px", fontSize: "12px" }}
              />
            </div>
          </div>
          {influencer.boards?.map((board) => (
            <div className="board" key={board.id}>
              {board.name}
            </div>
          ))}
        </div>
        <div className="notes-container">
          <div className="flex-container">
            <img src={NoteIcon} className="icon" alt="" />
            <div className="heading">Notes</div>
            <div style={{ marginLeft: "auto" }}>
              <Button
                type="button"
                label="Add a note"
                onClick={() => openNotesModal(influencer.id, null, createNote)}
                theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "3px 12px", fontSize: "12px" }}
              />
            </div>
          </div>
          {influencer.notes?.map((note) => (
            <div className="note" key={note.id}>
              <div className="text">{note.content}</div>
              <div className="dropdown-container">
                <IconDropdownMenu>
                  <MenuItem onClick={() => openNotesModal(note.id, note.content, editNote)}>
                    <div className="mui-dropdown-menu-item-container">
                      <img src={EditIcon} alt="" className="dropdown-item-icon" />
                      <div className="dropdown-item-text">Edit note</div>
                    </div>
                  </MenuItem>
                  <MenuItem onClick={() => removeNote(note.id)}>
                    <div className="mui-dropdown-menu-item-container">
                      <img src={RemoveIcon} alt="" className="dropdown-item-icon" />
                      <div className="dropdown-item-text" style={{ color: "red" }}>
                        Remove note
                      </div>
                    </div>
                  </MenuItem>
                </IconDropdownMenu>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  )
}

export default Report
