import React, { useState, useEffect } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"

const AuthGuard = () => {
  const navigate = useNavigate()

  const [allowed, setAllowed] = useState(false)

  const { user, token } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!(token && user) || (user && token && !user?.role)) {
      setAllowed(true)
    } else if (token && user && user?.role) {
      navigate(`/${user.role}/dashboard`)
    }
  }, [navigate, token, user])

  return allowed && <Outlet />
}

export default AuthGuard
