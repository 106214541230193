import React, { useState } from "react"
import Select, { components } from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"
import Tooltip from "@mui/material/Tooltip"
import { Form, FormCheck } from "react-bootstrap"
import TooltipIcon from "../../../assets/images/tooltip.svg"
import CrossIcon from "../../../assets/images/cross.svg"
import "./styles.scss"

const initialize = (name, state, setState) => {
  if (!(name in state)) {
    setState((prev) => ({
      ...prev,
      [name]: null,
    }))
  }
}

const clearState = (name, setState) => {
  setState((prev) => ({
    ...prev,
    [name]: null,
  }))
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <FormCheck.Input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const WeightSelector = ({ value, isMulti, name, options, state, setState }) => {
  const handleChange = (option) => {
    let newValue

    if (isMulti) {
      newValue = state[name].map((item) => (item.value === value.value ? { ...item, weight: option.value } : item))
    } else {
      newValue = { ...state[name], weight: option.value }
    }

    setState((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  const selectedValue = isMulti
    ? options.find((opt) => opt.value === state[name]?.find((item) => item.value === value.value)?.weight)
    : options.find((opt) => opt.value === state[name]?.weight)

  if (state[name]) {
    if (Array.isArray(state[name])) {
      if (!state[name].find((item) => item.value === value.value && item?.weight)) {
        handleChange(options[0])
      }
    } else if (!state[name]?.weight) {
      handleChange(options[0])
    }
  }

  return (
    <div className="weight-filter-container">
      <Select classNamePrefix="react-select" placeholder="Select..." options={options} value={selectedValue || options[0]} onChange={handleChange} isSearchable={false} />
    </div>
  )
}

const FilterContainer = ({ name, label, tooltip, state, setState, children }) => (
  <div className="filter-container">
    <div className="flex-container">
      <div className="label">{label}</div>
      {tooltip && (
        <Tooltip title={tooltip} placement="top" arrow>
          <img src={TooltipIcon} className="tooltip-icon" alt="" />
        </Tooltip>
      )}
      {state[name] && (Array.isArray(state[name]) ? state[name].length > 0 : true) && (
        <div className="clear-button" onClick={() => clearState(name, setState)}>
          Clear
        </div>
      )}
    </div>
    <>{children}</>
  </div>
)

const TextFilter = ({ name, label, tooltip, state, setState }) => {
  const handleChange = (event) => {
    initialize(name, state, setState)
    const value = event.target.value
    setState((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="text-filter-container">
        <Form.Control className="text-filter" placeholder="Enter..." value={state[name] || ""} onChange={handleChange} />
      </div>
    </FilterContainer>
  )
}

const RangeFilter = ({ name, label, tooltip, fromOptions, toOptions, state, setState }) => {
  const handleChange = (option, isFrom) => {
    initialize(name, state, setState)
    const value = option ? option.value : null
    setState((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        [isFrom ? "min" : "max"]: value,
      },
    }))
  }

  const filteredToOptions = toOptions.filter((option) => !state[name]?.min || option.value > state[name]?.min)
  const filteredFromOptions = fromOptions.filter((option) => !state[name]?.max || option.value < state[name]?.max)

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="flex-container">
        <div className="range-filter-container">
          <Select
            placeholder="Min"
            classNamePrefix="react-select"
            options={filteredFromOptions}
            value={fromOptions.find((option) => option.value === state[name]?.min) || null}
            onChange={(option) => handleChange(option, true)}
            noOptionsMessage={() => null}
          />
        </div>
        <div className="range-filter-container">
          <Select
            placeholder="Max"
            classNamePrefix="react-select"
            options={filteredToOptions}
            value={toOptions.find((option) => option.value === state[name]?.max) || null}
            onChange={(option) => handleChange(option, false)}
            noOptionsMessage={() => null}
          />
        </div>
      </div>
    </FilterContainer>
  )
}

const AsyncSelectFilter = ({ name, label, tooltip, service, platform, weights, state, setState, placeholder }) => {
  const loadOptions = (value) => {
    return service(value, platform).then((response) => {
      return response.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    })
  }

  const handleChange = (option) => {
    initialize(name, state, setState)
    setState((prev) => ({
      ...prev,
      [name]: option,
    }))
  }

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="async-select-filter-container">
        <AsyncSelect
          cacheOptions
          classNamePrefix="react-select"
          loadOptions={loadOptions}
          placeholder={placeholder}
          value={state[name]}
          onChange={handleChange}
          noOptionsMessage={() => null}
        />
      </div>
      {weights && state[name] && (
        <div className="options-container">
          <div className="option-text">{state[name].label}</div>
          <WeightSelector value={state[name]} name={name} options={weights} state={state} setState={setState} />
        </div>
      )}
    </FilterContainer>
  )
}

const SelectFilter = ({ name, label, tooltip, options, weights, state, setState, placeholder }) => {
  const handleChange = (option) => {
    initialize(name, state, setState)
    setState((prev) => ({
      ...prev,
      [name]: option,
    }))
  }

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="select-filter-container">
        <Select
          classNamePrefix="react-select"
          placeholder={placeholder}
          value={state[name] || null}
          options={options}
          onChange={handleChange}
          noOptionsMessage={() => null}
        />
      </div>
      {weights && state[name] && (
        <div className="options-container">
          <div className="option-text">{state[name].label}</div>
          <WeightSelector value={state[name]} name={name} options={weights} state={state} setState={setState} />
        </div>
      )}
    </FilterContainer>
  )
}

const MultiSelectFilter = ({ name, label, tooltip, options, weights, limit, state, setState, placeholder }) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (query, { action }) => {
    if (action !== "set-value") {
      setInputValue(query)
      return query
    }
    return inputValue
  }

  const handleChange = (options) => {
    initialize(name, state, setState)
    setState((prev) => ({
      ...prev,
      [name]: options,
    }))
  }

  const clearValue = (option) => {
    setState((prev) => ({
      ...prev,
      [name]: prev[name].filter((item) => item.value !== option.value),
    }))
  }

  const isLimitReached = (state[name] || []).length >= limit

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="multi-select-filter-container">
        <Select
          classNamePrefix="react-select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          components={{
            Option,
          }}
          value={state[name] || null}
          options={options}
          onChange={handleChange}
          placeholder={placeholder}
          noOptionsMessage={() => null}
          inputValue={inputValue}
          isDisabled={isLimitReached}
          onInputChange={handleInputChange}
        />
      </div>
      {weights &&
        state[name] &&
        state[name].map((value) => (
          <div className="options-container" key={value.label}>
            <div className="flex-container">
              <div className="clear-button" onClick={() => clearValue(value)}>
                <img src={CrossIcon} className="cross-icon" alt="" />
              </div>
              <div className="option-text">{value.label}</div>
            </div>
            <WeightSelector value={value} isMulti={true} name={name} options={weights} state={state} setState={setState} />
          </div>
        ))}
      {isLimitReached && <div style={{ color: "red", fontSize: "10px" }}>You can only select up to {limit} items</div>}
    </FilterContainer>
  )
}

const AsyncMultiSelectFilter = ({ name, label, tooltip, service, platform, weights, limit, state, setState, placeholder }) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (query, { action }) => {
    if (action !== "set-value") {
      setInputValue(query)
      return query
    }
    return inputValue
  }

  const loadOptions = () => {
    return service(inputValue, platform).then((response) => {
      return response.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    })
  }

  const handleChange = (options) => {
    initialize(name, state, setState)
    if (options.length <= limit) {
      setState((prev) => ({
        ...prev,
        [name]: options,
      }))
    }
  }

  const clearValue = (option) => {
    setState((prev) => ({
      ...prev,
      [name]: prev[name].filter((item) => item.value !== option.value),
    }))
  }

  const isLimitReached = (state[name] || []).length >= limit

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="multi-select-filter-container">
        <AsyncSelect
          key={platform}
          cacheOptions
          loadOptions={loadOptions}
          placeholder={placeholder}
          classNamePrefix="react-select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          components={{
            Option,
          }}
          value={state[name] || null}
          onChange={handleChange}
          noOptionsMessage={() => null}
          isDisabled={isLimitReached}
          inputValue={inputValue}
          onInputChange={handleInputChange}
        />
      </div>
      {weights &&
        state[name] &&
        state[name].map((value) => (
          <div className="options-container" key={value.label}>
            <div className="flex-container">
              <div className="clear-button" onClick={() => clearValue(value)}>
                <img src={CrossIcon} className="cross-icon" alt="" />
              </div>
              <div className="option-text">{value.label}</div>
            </div>
            <WeightSelector value={value} isMulti={true} name={name} options={weights} state={state} setState={setState} />
          </div>
        ))}
      {isLimitReached && <div style={{ color: "red", fontSize: "10px" }}>You can only select up to {limit} items</div>}
    </FilterContainer>
  )
}

const AsyncCreatableFilter = ({ name, label, tooltip, service, platform, limit, state, setState, placeholder }) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (query, { action }) => {
    if (action !== "set-value") {
      setInputValue(query)
      return query
    }
    return inputValue
  }

  const loadOptions = (value) => {
    return service(value, platform).then((response) => {
      return response.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    })
  }

  const handleChange = (options) => {
    initialize(name, state, setState)
    if (options.length <= limit) {
      setState((prev) => ({
        ...prev,
        [name]: options,
      }))
    }
  }

  const isLimitReached = (state[name] || []).length >= limit

  return (
    <FilterContainer name={name} label={label} tooltip={tooltip} state={state} setState={setState}>
      <div className="multi-select-filter-container">
        <AsyncCreatableSelect
          key={platform}
          cacheOptions
          loadOptions={loadOptions}
          placeholder={placeholder}
          classNamePrefix="react-select"
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          components={{
            Option,
          }}
          value={state[name] || null}
          onChange={handleChange}
          noOptionsMessage={() => null}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          isDisabled={isLimitReached}
          inputValue={inputValue}
          onInputChange={handleInputChange}
        />
      </div>
      {isLimitReached && <div style={{ color: "red", fontSize: "10px" }}>You can only select up to {limit} items</div>}
    </FilterContainer>
  )
}

export { RangeFilter, SelectFilter, AsyncSelectFilter, TextFilter, MultiSelectFilter, AsyncMultiSelectFilter, AsyncCreatableFilter }
