import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";

const usePaymentMethodService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const list = async () => {
    try {
      const response = await axiosRequest.get("payment_methods");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const setupIntent = async () => {
    try {
      const response = await axiosRequest.get("payment_methods/intent");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const deleteIntents = async () => {
    try {
      const response = await axiosRequest.delete(
        "payment_methods/destroy_intents"
      );
      return response;
    } catch (error) {
      console.log(error.response.data.error);
      return false;
    }
  };

  const destroy = async (id) => {
    try {
      const response = await axiosRequest.delete(`payment_methods/${id}`);
      return response;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const update = async (id) => {
    try {
      const response = await axiosRequest.put(`payment_methods/${id}`);
      return response;
    } catch (error) {
      notify.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  return {
    list,
    setupIntent,
    update,
    destroy,
    deleteIntents,
  };
};

export default usePaymentMethodService;
