import React from "react"
import { Button } from "../../Common/CommonElements"
import "./styles.scss"

const NoFiltersView = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <div className="find-influencers-no-filters-view">
      <div className="heading">It appears that you haven't applied any filters yet.</div>
      <div className="paragraph">Get started with influencer discovery by entering a target audience location and setting a follower range.</div>
      <Button label="Back to filters" onClick={scrollToTop} theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }} />
    </div>
  )
}

export default NoFiltersView
