import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog } from "@mui/material"
import { Button, LoadingButton } from "../../Common/CommonElements"
import "./styles.scss"
import WarningIcon from "../../../assets/images/toast-alert.svg"

const BoardWarningModal = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [callback, setCallback] = useState(null)
  const [title, setTitle] = useState(null)

  const openModal = (id, callback, title) => {
    setId(id)
    setOpen(true)
    setTitle(title)
    setCallback(() => callback)
  }

  const closeModal = () => {
    setId(null)
    setCallback(false)
    setTitle(false)
    setOpen(false)
  }

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    setLoading,
  }))

  return (
    <Dialog open={open} onClose={closeModal} className="warning-modal">
      <div className="modal-container">
        <div className="icon-container">
          <img src={WarningIcon} className="icon" alt="warning" />
        </div>
        <div className="inner-container">
          <div className="primary-text">Remove {title}</div>
          <div className="secondary-text">Are you certain you want to delete this? Removing this will result in the loss of influencers associated with it.</div>
          <div className="button-group">
            <Button
              type="button"
              label="Back"
              onClick={closeModal}
              theme={{ backgroundColor: "#f6f7fb", color: "#000000", padding: "0.8rem 2.2rem", fontSize: "14px" }}
            />
            <LoadingButton
              type="button"
              label="Remove"
              onClick={() => callback(id)}
              isLoading={loading}
              theme={{ backgroundColor: "#f65353", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px" }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
})

export default BoardWarningModal
