import React from "react";

import MasterCardLogo from "../../../assets/images/master_card_logo.svg";
import CrossLogo from "../../../assets/images/cross.svg";
import "./styles.scss";

const CardDetail = ({
  paymentMethod,
  openRemoveModal,
  openSetDefaultModal,
}) => {
  return (
    <div className="row">
      <div className="col-2 card-bg-black">
        <img src={MasterCardLogo} />
      </div>
      <div className="col-5 d-flex flex-column">
        <span className="detail-text">
          **** **** ****{paymentMethod.last4_digits}
        </span>
        <span className="mt-2 detail-text">
          Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}
        </span>
      </div>
      <div className="col-3">
        {paymentMethod.default && (
          <span className="default-badge">Default</span>
        )}
        {!paymentMethod.default && (
          <span
            className="set-default"
            onClick={() => {
              openSetDefaultModal(paymentMethod.id);
            }}
          >
            Set as default
          </span>
        )}
      </div>

      {!paymentMethod.default && (
        <div
          className="col-1 cursor-pointer"
          onClick={() => {
            openRemoveModal(paymentMethod.id);
          }}
        >
          <img src={CrossLogo} />
        </div>
      )}
    </div>
  );
};

export default CardDetail;
