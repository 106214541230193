import React, { useState, useCallback } from "react"
import { components } from "react-select"
import AsyncSelect from "react-select/async"
import debounce from "lodash.debounce"
import "./styles.scss"
import DefaultProfilePicture from "../../../assets/images/default-profile-picture.svg"
import SearchIcon from "../../../assets/images/search.svg"

const Option = (props) => (
  <components.Option {...props}>
    <div className="option">
      <img src={props.data.picture || DefaultProfilePicture} alt="" className="image" />
      <div className="username">@{props.label}</div>|<div className="followers">{props.data.followers || "N/A"} followers</div>
    </div>
  </components.Option>
)

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={SearchIcon} alt="" />
    </components.DropdownIndicator>
  )
}

const customStyles = {
  control: (base) => ({
    ...base,
    flexDirection: "row-reverse",
  }),
  clearIndicator: (base) => ({
    ...base,
    position: "absolute",
    right: "10px",
  }),
  loadingIndicator: (base) => ({
    ...base,
    position: "absolute",
    right: "10px",
  }),
}

const SearchBar = ({ name, service, platform, limit, state, setState }) => {
  const [inputValue, setInputValue] = useState("")

  const handleInputChange = (query, { action }) => {
    if (action !== "set-value") {
      setInputValue(query)
      return query
    }
    return inputValue
  }

  const debouncedLoadOptions = useCallback(
    debounce((value, callback) => {
      service(value, platform).then((response) => {
        const options = response.map((option) => ({
          label: option.name,
          value: option.id,
          picture: option.picture,
          followers: option.followers,
        }))
        callback(options)
      })
    }, 500),
    [service, platform]
  )

  const handleChange = (options) => {
    if (!(name in state)) {
      setState((prev) => ({
        ...prev,
        [name]: null,
      }))
    }

    if (options.length <= limit) {
      setState((prev) => ({
        ...prev,
        [name]: options,
      }))
    }
  }

  const isLimitReached = (state[name] || []).length >= limit
  const menuShouldOpen = inputValue.length > 0

  return (
    <div className="search-bar-filter-container">
      <AsyncSelect
        key={platform}
        cacheOptions
        loadOptions={debouncedLoadOptions}
        placeholder="Search an influencer by @username/handle"
        classNamePrefix="react-select"
        isMulti
        closeMenuOnSelect={!isLimitReached}
        hideSelectedOptions={false}
        isClearable={true}
        components={{ Option, DropdownIndicator }}
        value={state[name] || null}
        onChange={handleChange}
        noOptionsMessage={() => null}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        menuIsOpen={menuShouldOpen}
        styles={customStyles}
      />
    </div>
  )
}

export default SearchBar
