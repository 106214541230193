import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import useProfileService from "../../../services/useProfileService"
import { LoadingButton, PasswordInput } from "../../../components/Common/CommonElements"

const Password = () => {
  const profileService = useProfileService()
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current password is required"),
      newPassword: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[\W_]).+$/, "Password must contain upper and lower case characters, and at least one number or symbol"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      profileService.updatePassword(values).finally(() => {
        setIsLoading(false)
      })
    },
  })

  const { values, touched, errors, dirty } = formik

  return (
    <div>
      <div class="tab-pane">
        <form onSubmit={formik.handleSubmit} className="form">
          <div className="primary-text mb-5">Update your password</div>
          <div className="form-fields-container">
            <div className="form-field-container">
              <PasswordInput
                label="Current Password"
                name="currentPassword"
                value={values.currentPassword}
                onChange={formik.handleChange("currentPassword")}
                error={touched.currentPassword && errors.currentPassword}
              />
            </div>
            <div className="form-field-container">
              <PasswordInput
                label="New Password"
                name="newPassword"
                value={values.newPassword}
                onChange={formik.handleChange("newPassword")}
                error={touched.newPassword && errors.newPassword}
              />
            </div>
            <div className="form-field-container">
              <PasswordInput
                label="Confirm New Password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={formik.handleChange("confirmPassword")}
                error={touched.confirmPassword && errors.confirmPassword}
              />
            </div>
          </div>
          <LoadingButton
            type="submit"
            label="Update Profile"
            theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
            isLoading={isLoading}
            isDisabled={!dirty}
          />
        </form>
      </div>
    </div>
  )
}

export default Password
