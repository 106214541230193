import React from "react"
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from "@react-oauth/google"
import useAuthService from "../../../services/useAuthService"
import { Button } from "../../Common/CommonElements"
import GoogleIcon from "../../../assets/images/google.svg"

const GoogleAuth = () => {
  const navigate = useNavigate()
  const authService = useAuthService()

  const callback = useGoogleLogin({
    onSuccess: async (res) => {
      const params = { token: res.access_token, provider: "google" }
      authService.socialAuthentication(params).then((data) => {
        if (data && !data.role) {
          navigate("/set_role")
        } else if (data && data.role) {
          navigate(`/${data.role}/dashboard`)
        }
      })
    },
    onFailure: () => {},
  })

  return (
    <Button
      type="button"
      icon={<img src={GoogleIcon} alt="" />}
      onClick={callback}
      theme={{ backgroundColor: "#f6f7fb", color: "#000000", padding: "0.8rem 2.2rem", fontSize: "12px", width: "240px" }}
    />
  )
}

export default GoogleAuth
