import React, { useState, useEffect, useRef } from "react"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import * as Yup from "yup"
import useAuthService from "../../../services/useAuthService"
import { TextInput, PasswordInput, LoadingButton } from "../../../components/Common/CommonElements"
import GoogleAuth from "../../../components/Authentication/Google"
import FacebookAuth from "../../../components/Authentication/Facebook"
import ReCAPTCHA from "react-google-recaptcha"
import notify from "../../../components/Common/Toast"
import { setRedirectUrl } from "../../../redux/reducers/authReducer"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"
import InfluencersProfiles from "../../../assets/images/influencers-profiles.svg"
import { influencerTypeWithLocation } from "../../../constants"
import { getRelativeUrl, isDayOld } from "../../../helpers"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const authService = useAuthService()
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [loading, setLoading] = useState(false)
  const [index, setIndex] = useState(0)

  const recaptchaRef = useRef(null)

  const { user, redirectUrl } = useSelector((state) => state.auth)

  const { isLoading, error, data, getData } = useVisitorData({ extendedResult: true }, { immediate: true })

  useEffect(() => {
    if (user && !isLoading && !error && data) {
      authService.addUserFingerprint(user?.id, data.visitorId)
    }
  }, [user, isLoading])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % influencerTypeWithLocation.length)
    }, 3000)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const wordSpans = document.querySelectorAll(".special_word")
    wordSpans?.forEach((wordSpan) => {
      wordSpan.classList.remove("show")
      setTimeout(() => {
        wordSpan.classList.add("show")
      }, 2000)
    })
  }, [index])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      if (!recaptchaToken) {
        notify.error("Please verify the ReCAPTCHA")
        return
      }

      setLoading(true)
      authService
        .login(values, recaptchaToken)
        .then((data) => {
          if (data) {
            getData({ ignoreCache: true })
            if (redirectUrl) {
              if (!isDayOld(redirectUrl.createdAt)) navigate(getRelativeUrl(redirectUrl.url))
              dispatch(setRedirectUrl(null))
            } else {
              navigate(`/${data.role}/dashboard`)
            }
          } else {
            recaptchaRef.current.reset()
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const { values, touched, errors } = formik

  const handleRecaptcha = (value) => {
    setRecaptchaToken(value)
  }

  return (
    <div className="login-page">
      <div className="row">
        <div className="col-5 d-lg-block d-none">
          <div className="background-container">
            <div className="heading-text">
              Find <span className="special_word">{influencerTypeWithLocation[index].type}</span> influencers <br /> in{" "}
              <span className="special_word">{influencerTypeWithLocation[index].location}</span>
            </div>
            <div className="image-container">
              <img src={InfluencersProfiles} className="image" alt="" />
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-12">
          <div className="form-container">
            <form onSubmit={formik.handleSubmit} className="form">
              <div className="logo-container" onClick={() => (window.location.href = process.env.REACT_APP_LANDING_PAGE_URL)}>
                <img src={Logo} className="logo" alt="" />
              </div>
              <div className="primary-text">Welcome Back</div>
              <div className="form-fields-container">
                <div className="form-field-container">
                  <TextInput label="Email" name="email" value={values.email} onChange={formik.handleChange("email")} error={touched.email && errors.email} />
                </div>
                <div className="form-field-container">
                  <PasswordInput label="Password" name="password" value={values.password} onChange={formik.handleChange("password")} error={touched.password && errors.password} />
                </div>
              </div>
              <div className="recaptcha-container">
                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} ref={recaptchaRef} />
              </div>
              <div style={{ marginBottom: "2rem" }}>
                <LoadingButton type="submit" label="Login" theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }} isLoading={loading} />
              </div>
              <div className="social-buttons-container">
                <GoogleAuth />
                <FacebookAuth />
              </div>
              <div className="actions-container">
                <div>
                  New to Hive ?{" "}
                  <Link to="/register" className="link">
                    Register
                  </Link>
                </div>
                <Link to="/forgot_password" className="link">
                  Forgot Password
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
