//Confirmation Modal
import React from "react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import CrossIcon from "../../../assets/images/cross.svg"
import CancelAlertIcon from "../../../assets/images/cancel_alert.svg"

const ConfirmationModal = ({ open, onClose, onConfirm, cancelButtonText, confirmButtonText, confirmButtonColor, modalHeadingText, confirmationText, cancelAlert }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="d-flex justify-content-end pe-3 pt-3">
        <img src={CrossIcon} className="remove-button cursor-pointer" alt="" onClick={onClose} />
      </div>
      <div style={{ paddingBottom: "20px", textAlign: "center" }}>
        {cancelAlert && <img src={CancelAlertIcon} className="remove-button cursor-pointer" alt="" />}
        <div className="d-flex justify-content-center">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "start",
              color: "var(--Text, #1A1D1F)",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "150%",
              marginBottom: "-10px",
            }}>
            {modalHeadingText}
          </DialogTitle>
        </div>

        <DialogContent
          sx={{
            textAlign: "center",
            color: "#141416",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "150%",
          }}>
          {confirmationText}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "12px",
            marginTop: "15px",
          }}>
          <Button
            onClick={onClose}
            variant="outlined"
            sx={{
              color: "black",
              border: "1px solid #e2e8f0 !important",
              borderRadius: "20px",
              display: "inline-flex",
              padding: "10px 20px",
              width: "151px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              backgroundColor: "#F6F7FB !important",
              textTransform: "inherit",
            }}>
            {cancelButtonText}
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{
              color: "#FFF",
              border: `1px solid ${confirmButtonColor}`,
              borderRadius: "20px",
              display: "inline-flex",
              padding: "10px 20px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              width: "151px",
              backgroundColor: `${confirmButtonColor} !important`,
              textTransform: "inherit",
            }}>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export default ConfirmationModal
