import React from "react"
import { ToastContainer } from "react-toastify"

const ToastProvider = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={false}
        newestOnTop={true}
        limit={3}
        className="toast-outer-container"
        toastClassName="toast-inner-container"
        bodyClassName="toast-body"
      />
    </>
  )
}

export default ToastProvider
