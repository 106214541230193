import React, { useState } from "react"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import useInfluencerService from "../../../services/useInfluencerService"
import {
  platforms,
  numberRange,
  ageRange,
  influencerGenderOptions,
  audienceGenderOptions,
  engagementOptions,
  lastPostOptions,
  fakeFollowersOptions,
  accountOptions,
  contactOptions,
  weightOptions,
  growthRateOptions,
  extendedWeightOptions,
  audienceAgeOptions,
  interestsOptions,
} from "../../../constants"
import { SelectFilter, RangeFilter, AsyncSelectFilter, TextFilter, MultiSelectFilter, AsyncMultiSelectFilter, AsyncCreatableFilter } from "../Filters"
import "./styles.scss"

const FiltersContainer = ({ currentPlatform, influencerFilters, setInfluencerFilters, audienceFilters, setAudienceFilters }) => {
  const influencerService = useInfluencerService()

  const [basicAccordionExpanded, setBasicAccordionExpanded] = useState(true)
  const [advancedAccordionExpanded, setAdvancedAccordionExpanded] = useState(false)

  return (
    <div className="accordian-container">
      <Accordion className="filters-container" expanded={basicAccordionExpanded} onChange={() => setBasicAccordionExpanded(!basicAccordionExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="filter-family-heading">Basic Filters</div>
        </AccordionSummary>
        <AccordionDetails className="advanced-filters-container">
          <div className="filters">
            <RangeFilter name="followers" label="Followers" fromOptions={numberRange.from} toOptions={numberRange.to} state={influencerFilters} setState={setInfluencerFilters} />
            <SelectFilter name="gender" label="Influencer Gender" options={influencerGenderOptions} state={influencerFilters} setState={setInfluencerFilters} placeholder="Any" />
            <AsyncMultiSelectFilter
              name="location"
              label="Influencer Locations"
              service={influencerService.filterLocations}
              platform={currentPlatform}
              limit={20}
              state={influencerFilters}
              setState={setInfluencerFilters}
              placeholder={currentPlatform === platforms[0] ? "Search by country/city" : "Search by country"}
            />
            <AsyncCreatableFilter
              name="topics"
              label="Topics"
              service={influencerService.filterTopics}
              platform={currentPlatform}
              limit={20}
              state={influencerFilters}
              setState={setInfluencerFilters}
              placeholder="Topics"
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion className="filters-container" expanded={advancedAccordionExpanded} onChange={() => setAdvancedAccordionExpanded(!advancedAccordionExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="filter-family-heading">Advanced Filters</div>
        </AccordionSummary>
        <AccordionDetails className="advanced-filters-container">
          <div className="advanced-filters">
            <div className="filter-family-heading">Influencer</div>
            <div className="filters">
              {[platforms[0]].includes(currentPlatform) && (
                <MultiSelectFilter
                  name="accountTypes"
                  label="Account Type"
                  tooltip="Filter influencers by their Instagram account type—be it Business, Creator, or Personal—to match your campaign needs."
                  options={accountOptions}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                  placeholder="Any"
                />
              )}
              <RangeFilter name="age" label="Age" fromOptions={ageRange.from} toOptions={ageRange.to} state={influencerFilters} setState={setInfluencerFilters} />
              <TextFilter
                name="bio"
                label="Bio"
                tooltip="Search for influencers by keywords in their bio. Use descriptors they might choose to pinpoint the right profiles, like 'Amazon' for those associated with the brand."
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Any"
              />
              {[platforms[0]].includes(currentPlatform) && (
                <AsyncMultiSelectFilter
                  name="brands"
                  label="Partnerships"
                  tooltip="Find influencers linked to specific brands, including top names like Coca-Cola, through sponsored content flagged by Instagram’s Paid Partnership or hashtags such as #ad, #sponsored, and #paid."
                  service={influencerService.filterBrands}
                  platform={currentPlatform}
                  limit={20}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                  placeholder="Any"
                />
              )}
              <SelectFilter
                name="engagementRate"
                label="Engagement Rate"
                tooltip="Identify influencers with the most interactive audiences. Engagement Rate indicates the percentage of followers who have interacted with the influencer's content"
                options={engagementOptions}
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Any"
              />
              <MultiSelectFilter
                name="hasContactDetails"
                label="Contact Information"
                tooltip="Find influencers based on their available Contact Information. Our Contact Information is updated regularly to ensure only active, publicly listed contacts are included for outreach."
                options={contactOptions}
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Any"
              />
              <SelectFilter
                name="followersGrowthRate"
                label="Growth Rate"
                options={growthRateOptions}
                weights={extendedWeightOptions}
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Any Interval"
              />
              <TextFilter
                name="keywords"
                label="Keywords"
                tooltip="Identify influencers who incorporate specific phrases in their post captions. This tool is key for pinpointing content that aligns with your brand's messaging or campaign themes."
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Keywords"
              />
              {[platforms[0]].includes(currentPlatform) && (
                <MultiSelectFilter
                  name="interests"
                  label="Interests"
                  options={interestsOptions}
                  platform={currentPlatform}
                  limit={20}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                  placeholder="Any"
                />
              )}
              <AsyncSelectFilter
                name="language"
                label="Language"
                service={influencerService.filterLanguages}
                platform={currentPlatform}
                state={influencerFilters}
                setState={setInfluencerFilters}
                placeholder="Any"
              />
              <SelectFilter name="lastposted" label="Last Post" options={lastPostOptions} state={influencerFilters} setState={setInfluencerFilters} placeholder="Any" />
              {[platforms[0]].includes(currentPlatform) && (
                <RangeFilter
                  name="reelsPlays"
                  label="Reels Plays"
                  tooltip="Search for influencers based on how frequently their latest reels are viewed. ‘Average Reels Plays’ indicates the typical number of views across recent videos, helping you gauge current engagement."
                  fromOptions={numberRange.from}
                  toOptions={numberRange.to}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                />
              )}
              {[platforms[1], platforms[2]].includes(currentPlatform) && (
                <RangeFilter name="views" label="Views" fromOptions={numberRange.from} toOptions={numberRange.to} state={influencerFilters} setState={setInfluencerFilters} />
              )}
              {[platforms[0], platforms[2]].includes(currentPlatform) && (
                <AsyncCreatableFilter
                  name="hashtags"
                  label="Hashtags"
                  service={influencerService.filterHashtags}
                  platform={currentPlatform}
                  limit={10}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                  placeholder="#hashtags"
                />
              )}
              {[platforms[0], platforms[2]].includes(currentPlatform) && (
                <AsyncCreatableFilter
                  name="mentions"
                  label="Mentions"
                  tooltip={
                    "Search for influencers who have mentioned or tagged other accounts in their posts. This filter is useful for finding creators who actively engage with or endorse other users and brands."
                  }
                  service={influencerService.searchInfluencers}
                  platform={currentPlatform}
                  limit={10}
                  state={influencerFilters}
                  setState={setInfluencerFilters}
                  placeholder="@username"
                />
              )}
            </div>
          </div>
          <div className="advanced-filters">
            <div className="filter-family-heading">Audience</div>
            <div className="filters">
              {[platforms[0]].includes(currentPlatform) && (
                <SelectFilter name="credibility" label="Fake Followers" options={fakeFollowersOptions} state={audienceFilters} setState={setAudienceFilters} />
              )}
              <SelectFilter
                name="gender"
                label="Gender"
                options={audienceGenderOptions}
                weights={weightOptions}
                tooltip={
                  "Begin with the standard percentage options to categorize influencers' audiences by gender. Fine-tune the slider to narrow your focus. The higher the percentage you select, the more specifically targeted your search results will be for that gender group."
                }
                state={audienceFilters}
                setState={setAudienceFilters}
                placeholder="Any"
              />
              <MultiSelectFilter
                name="age"
                label="Age"
                options={audienceAgeOptions}
                tooltip="Set off with the pre-configured percentage ranges to gauge the age distribution of influencers' audiences. As you adjust the percentages, your search will increasingly concentrate on influencers with a predominant audience in your specified age group."
                weights={weightOptions}
                state={audienceFilters}
                setState={setAudienceFilters}
                placeholder="Any"
              />
              <AsyncSelectFilter
                name="language"
                label="Language"
                service={influencerService.filterLanguages}
                platform={currentPlatform}
                weights={weightOptions}
                state={audienceFilters}
                tooltip={
                  "Initiate your search with the standard percentage settings to assess the language diversity among an influencer's audience. Modulate the percentages to pinpoint a dominant language presence—selecting a higher percentage refines your results to influencers with a larger audience segment speaking that language."
                }
                setState={setAudienceFilters}
                placeholder="Any"
              />
              {[platforms[0]].includes(currentPlatform) && (
                <AsyncMultiSelectFilter
                  name="interests"
                  label="Interests"
                  service={influencerService.filterInterests}
                  platform={currentPlatform}
                  tooltip={
                    "Kick off your search with the default percentage settings to see the scope of influencers' interests. Tailor the percentages to refine your search, focusing on those with a higher concentration of content in your area of interest"
                  }
                  weights={weightOptions}
                  limit={20}
                  state={audienceFilters}
                  setState={setAudienceFilters}
                  placeholder="Any"
                />
              )}
              <AsyncMultiSelectFilter
                name="location"
                label="Locations"
                service={influencerService.filterLocations}
                platform={currentPlatform}
                tooltip={
                  "Initiate your search using default settings to survey where an influencer's followers are primarily located. Adjust the slider to target a specific audience percentage in certain regions—higher settings yield influencers with a dominant following in your selected area."
                }
                weights={weightOptions}
                limit={20}
                state={audienceFilters}
                setState={setAudienceFilters}
                placeholder="Where is your audience ?"
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default FiltersContainer
