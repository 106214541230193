import React from "react"
import Select from "react-select"
import "./styles.scss"

const GenericSelect = ({ options, value, handleChange, isMulti, formatOptionLabel, placeholder, isSearchable }) => {
  const onChange = (selectedValue) => {
    handleChange(selectedValue)
  }

  return (
    <div className="generic-select-input">
      <Select
        placeholder={placeholder}
        classNamePrefix="react-select"
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        formatOptionLabel={formatOptionLabel}
        isSearchable={isSearchable}
      />
    </div>
  )
}

export default GenericSelect
