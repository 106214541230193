import React from "react"
import Image from "../../../assets/images/manage-influencers-default-view.svg"
import "./styles.scss"

const DefaultView = () => {
  return (
    <div className="mamange-influencers-default-view">
      <div className="image-container">
        <img src={Image} alt="" className="image" />
      </div>
      <div className="heading">Manage Influencers</div>
      <div className="paragraph">
        Start managing your content efficiently. <br /> Any influencer which you have viewed insights for can be found here.
      </div>
    </div>
  )
}

export default DefaultView
