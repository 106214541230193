import React, { useState } from "react"
import ReactJsPagination from "react-js-pagination"
import UpgradePopup from "../../Boards/UpgradePopup"
import { Tooltip } from "@mui/material"
import "./styles.scss"

const Pagination = ({ activePage, totalItems, itemsPerPage, onPageChange, lockFeatures }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const handlePageChange = (pageNumber) => {
    if (!lockFeatures || pageNumber < 3) {
      onPageChange(pageNumber - 1)
    } else {
      setTooltipOpen(true)
      setTimeout(() => {
        setTooltipOpen(false)
      }, 4000)
    }
  }

  return (
    <div className="pagination-container">
      <Tooltip
        title={<UpgradePopup content="This feature is locked for you. Please choose a subscription plan" />}
        placement="top"
        open={tooltipOpen}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        className="upgrade-popup-tooltip">
        <div>
          <ReactJsPagination
            activePage={activePage}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            prevPageText={"<"}
            nextPageText={">"}
            itemClass={"page-item"}
            linkClass={"page-link"}
            activeClass={"active"}
            hideFirstLastPages={false}
          />
        </div>
      </Tooltip>
    </div>
  )
}

export default Pagination
