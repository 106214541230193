import React from "react"

const Metrics = ({ title, metrics }) => {
  return (
    <div className="metrics-container">
      <div className="primary-text">{title}</div>
      <div className="flex-container">
        {metrics?.map((metric) => (
          <div className="metric">{metric}</div>
        ))}
      </div>
    </div>
  )
}

export default Metrics
