import React from "react"
import Tooltip from "@mui/material/Tooltip"
import { Img } from "react-image"
import Skeleton from "@mui/material/Skeleton"
import { IconDropdownMenu } from "../../Common/CommonElements"
import MenuItem from "@mui/material/MenuItem"
import UpgradePopup from "../../Boards/UpgradePopup"
import "./styles.scss"
import RemoveIcon from "../../../assets/images/remove.svg"
import CrossIcon from "../../../assets/images/cross.svg"
import InstagramIcon from "../../../assets/images/card-instagram-profile.svg"
import YoutubeIcon from "../../../assets/images/card-youtube-profile.svg"
import TiktokIcon from "../../../assets/images/card-tiktok-profile.svg"
import ViewInsightsIcon from "../../../assets/images/view-insights-icon.svg"

const BoardCard = ({ id, data, handleCardClick, removeCard, removeTag, handleAddTag, lockFeatures }) => {
  const platformIcons = {
    instagram: InstagramIcon,
    youtube: YoutubeIcon,
    tiktok: TiktokIcon,
  }

  return (
    <div className="card board-card">
      <div className="flex-container">
        <div className="image-container">
          <Img src={data.influencer.picture} className="image" loader={<Skeleton variant="rounded" width={90} height={90} />} />
          <div className="profile-button" onClick={() => window.open(data.influencer.profile, "_blank")}>
            <Tooltip title="Open profile on the influencer's platform" placement="top" arrow>
              <img src={platformIcons[data.influencer.platform]} alt={data.influencer.platform} className="icon" />
            </Tooltip>
          </div>
        </div>
        <div className="info-container">
          <div>
            <div className="name">{data.influencer.fullname}</div>
            <div className="handle">@{data.influencer.username}</div>
          </div>
          <div className="metrics-container">
            <div className="metrics me-2">
              <div className="number">{data.influencer.followers}</div>
              <div className="text">Followers</div>
            </div>
            <div className="metrics">
              <div className="number">{data.influencer.engagement_rate}</div>
              <div className="text">Engagement</div>
            </div>
          </div>
        </div>
      </div>
      <div className="tags-container">
        <div className="title">Tags</div>
        <div className="tags">
          <button className="add-tag-button" onClick={() => handleAddTag(id)}>
            + Add Tag
          </button>
          {data.tags.map((tag) => (
            <div key={tag.id} style={{ backgroundColor: tag.color }} className="tag-item">
              <div className="name">{tag.name}</div>
              <img src={CrossIcon} className="remove-button" alt="" onClick={() => removeTag(tag.id)} />
            </div>
          ))}
        </div>
      </div>
      <div className="fees-container">
        <div className="text">Fees</div>
        <div className="text">{data.fees}</div>
      </div>
      <div className="dropdown-container">
        <IconDropdownMenu>
          <MenuItem onClick={() => removeCard(id)}>
            <div className="mui-dropdown-menu-item-container">
              <img src={RemoveIcon} alt="" className="dropdown-item-icon" />
              <div className="dropdown-item-text" style={{ color: "red" }}>
                Remove Card
              </div>
            </div>
          </MenuItem>
        </IconDropdownMenu>
      </div>
      <Tooltip
        title={<UpgradePopup content="This feature is locked for you. Please choose a subscription plan" />}
        placement="top"
        disableHoverListener={!lockFeatures}
        disableFocusListener={!lockFeatures}
        disableTouchListener={!lockFeatures}
        leaveDelay={1000}
        className="upgrade-popup-tooltip">
        <div>
          <div className="view-insights-button" onClick={() => !lockFeatures && handleCardClick(data.influencer)}>
            <img src={ViewInsightsIcon} alt="" className="icon" />
          </div>
        </div>
      </Tooltip>
    </div>
  )
}

export default BoardCard
