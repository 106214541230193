import React from "react"
import { useSelector } from "react-redux"
import Intercom from "@intercom/messenger-js-sdk"

const IntercomComponent = () => {
  const currentUser = useSelector((state) => state.auth?.user)

  Intercom({
    app_id: "nb2w88mv",
    user_id: currentUser?.id,
    name: currentUser?.name,
    email: currentUser?.email,
    created_at: currentUser?.created_at,
  })

  return <></>
}

export default IntercomComponent
