import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog } from "@mui/material"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Checkbox } from "@mui/material"
import CircleIcon from "@mui/icons-material/Circle"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { LoadingButton, TextInput } from "../../Common/CommonElements"
import { tagColors } from "../../../constants"
import "./styles.scss"
import CrossIcon from "../../../assets/images/cross.svg"

const TagsModal = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [selectedColor, setSelectedColor] = useState(tagColors[0])
  const [callback, setCallback] = useState(null)

  const openModal = (id, callback) => {
    setId(id)
    setCallback(() => callback)
    setOpen(true)
  }

  const closeModal = () => {
    setId(null)
    setSelectedColor(tagColors[0])
    setCallback(false)
    resetForm()
    setOpen(false)
  }

  const handleColorChange = (color) => {
    setSelectedColor(color)
    formik.setFieldValue("color", color)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      content: "",
      color: tagColors[0],
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Content cannot be empty"),
    }),
    onSubmit: async (values) => {
      callback(values)
    },
  })

  const { values, touched, errors, resetForm } = formik

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    setLoading,
  }))

  return (
    <Dialog open={open} onClose={closeModal} className="tags-modal">
      <div className="modal-container">
        <button className="close-button" onClick={closeModal}>
          <img src={CrossIcon} alt="close" className="icon" />
        </button>
        <div className="modal-title">Add a new tag</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-field-container">
            <TextInput label="Tag Name" name="content" value={values.content} onChange={formik.handleChange("content")} error={touched.content && errors.content} />
          </div>
          <div className="form-label">Select a color</div>
          <div className="colors-container">
            {tagColors.map((color, index) => (
              <Checkbox
                key={index}
                className="checkbox-container"
                icon={<CircleIcon sx={{ fontSize: 40 }} />}
                checkedIcon={<CheckCircleIcon sx={{ fontSize: 40 }} />}
                checked={color === selectedColor}
                onChange={() => handleColorChange(color)}
                sx={{
                  color: color,
                  "&.Mui-checked": {
                    color: color,
                  },
                }}
              />
            ))}
          </div>
          <LoadingButton
            type="submit"
            label="Submit"
            isLoading={loading}
            theme={{ backgroundColor: "#000000", color: "white", padding: "0.8rem 2.2rem", fontSize: "14px" }}
          />
        </form>
      </div>
    </Dialog>
  )
})

export default TagsModal
