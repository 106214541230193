import React, { useState, useEffect } from "react"
import { useNavigate, Outlet } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setRedirectUrl } from "../../../../redux/reducers/authReducer"
import notify from "../../../Common/Toast"
import useProfileService from "../../../../services/useProfileService"

const RoleGuard = ({ role }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const profileService = useProfileService()

  const [allowed, setAllowed] = useState(false)
  const { user, token } = useSelector((state) => state.auth)

  useEffect(() => {
    const oneMonthAgo = new Date()
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

    if (user?.last_credit_provided_at > oneMonthAgo) {
      profileService.getProfile()
    }
  }, [])

  useEffect(() => {
    if (token && user && user?.role === role) {
      setAllowed(true)
    } else if (token && user && user?.role !== role) {
      notify.success("You are not authorized to access this dashboard")
      navigate(`/${user.role}/dashboard`)
    } else {
      notify.error("You need to login or register before continuing")
      dispatch(setRedirectUrl({ url: window.location.href, createdAt: new Date() }))
      navigate("/login")
    }
  }, [token, user, role])

  return allowed && <Outlet />
}

export default RoleGuard
