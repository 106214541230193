import React, { useState, useRef, useEffect } from "react"
import { Dialog, Tab } from "@mui/material"
import { TabList, TabPanel, TabContext } from "@mui/lab"
import { Button } from "../../../components/Common/CommonElements"
import Report from "../../../components/InfluencerDetails/Report"
import NotesModal from "../../../components/InfluencerDetails/NotesModal"
import BookmarkModal from "../../../components/Common/BookmarkModal"
import useInfluencerDetailsService from "../../../services/useInfluencerDetailsService"
import useBoardsService from "../../../services/useBoardsService"
import { FullScreenLoader } from "../../../components/Common/Loaders"
import InfluencerStats from "../../../components/InfluencerDetails/InfluencerStats"
import AudienceStats from "../../../components/InfluencerDetails/AudienceStats"
import Audience from "../../../components/InfluencerDetails/Audience"
import Posts from "../../../components/InfluencerDetails/Posts"
import { Modal } from "../../../components/Common/Modals"
import { useSelector } from "react-redux"
import notify from "../../../components/Common/Toast"
import "./styles.scss"
import CrossIcon from "../../../assets/images/cross.svg"
import RefreshIcon from "../../../assets/images/refresh-insights.svg"

const InfluencerInsights = ({ updateSavedInfluencers, updateBoards, influencerId, setInfluencerId }) => {
  const influencerDetailsService = useInfluencerDetailsService()
  const boardService = useBoardsService()

  const bookmarkModalRef = useRef()
  const bookmarkModal = bookmarkModalRef.current
  const notesModalRef = useRef()
  const notesModal = notesModalRef.current

  const credits = useSelector((state) => state.auth?.credits)

  const [isOpen, setIsOpen] = useState(false)
  const [influencer, setInfluencer] = useState(null)
  const [loading, setLoading] = useState(false)
  const [mainTab, setMainTab] = useState("influencer")
  const [secondaryTab, setSecondaryTab] = useState("influencerStats")
  const [shouldRefreshInsights, setShouldRefreshInsights] = useState(false)
  const [refreshModalIsOpen, setRefreshPopupIsOpen] = useState(false)

  const openRefreshModal = () => {
    setRefreshPopupIsOpen(true)
  }

  const closeRefreshModal = () => {
    setRefreshPopupIsOpen(false)
  }

  const refreshReport = () => {
    closeRefreshModal()
    if (credits >= 5) {
      setShouldRefreshInsights(true)
    } else {
      notify.error("Not enough credits to perform this action, please upgrade your plan")
    }
  }

  useEffect(() => {
    if (influencerId && (!influencer || shouldRefreshInsights)) {
      setIsOpen(true)
      setLoading(true)
      influencerDetailsService
        .insights(influencerId, shouldRefreshInsights)
        .then((data) => {
          if (data) {
            setInfluencer(data)
            setShouldRefreshInsights(false)
            updateSavedInfluencers((prev) => !prev)
          } else {
            setIsOpen(false)
            setShouldRefreshInsights(false)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [influencerId, shouldRefreshInsights])

  const closeModal = () => {
    setInfluencerId(null)
    setInfluencer(null)
    setMainTab("influencer")
    setSecondaryTab("influencerStats")
    setShouldRefreshInsights(false)
    setIsOpen(false)
  }

  const handleAddCardsToBoard = (influencers) => {
    bookmarkModal?.openModal(influencers, addCardsToBoard)
  }

  const addCardsToBoard = (values) => {
    bookmarkModal.setLoading(true)
    boardService.createCard(values).then((data) => {
      if (data) {
        updateBoards((prev) => !prev)
        bookmarkModal.setLoading(false)
        bookmarkModal.closeModal()
      }
    })
  }

  const createNote = async ({ id, content }) => {
    notesModal?.setLoading(true)
    influencerDetailsService.createNote(content, id).then((data) => {
      if (data) {
        setInfluencer((prev) => ({
          ...prev,
          notes: [{ id: data.id, content: data.content }, ...prev.notes],
        }))
        notesModal?.setLoading(false)
        notesModal?.closeModal()
      }
    })
  }

  const editNote = async ({ id, content }) => {
    notesModal?.setLoading(true)
    influencerDetailsService.editNote(id, content).then((data) => {
      if (data) {
        setInfluencer((prev) => ({
          ...prev,
          notes: prev.notes.map((note) => (note.id === id ? { ...note, content: content } : note)),
        }))
        notesModal?.setLoading(false)
        notesModal?.closeModal()
      }
    })
  }

  const removeNote = async (id) => {
    influencerDetailsService.removeNote(id).then((data) => {
      if (data) {
        setInfluencer((prev) => ({
          ...prev,
          notes: prev.notes.filter((note) => note.id !== id),
        }))
      }
    })
  }

  const handleMainTabChange = (event, newValue) => {
    setMainTab(newValue)
    setSecondaryTab(`${newValue}Stats`)
  }

  const handleSecondaryTabChange = (event, newValue) => {
    setSecondaryTab(newValue)
  }

  return (
    <>
      {(!loading || shouldRefreshInsights) && (
        <Dialog open={isOpen} onClose={closeModal} className="influencer-insights-modal">
          <div className="influencer-insights">
            <div className="header">
              <div className="refresh-insights-container">
                <div className="refresh-report-text">Last updated on {influencer?.report_updated_at}</div>
                <Button
                  type="button"
                  label="Refresh"
                  onClick={openRefreshModal}
                  icon={<img src={RefreshIcon} alt="" />}
                  theme={{ backgroundColor: "#ffffff", color: "#000000", padding: "3px 12px", fontSize: "12px", borderColor: "#E2E8F0" }}
                />
              </div>
              <button className="close-button" onClick={closeModal}>
                <img src={CrossIcon} className="icon" alt="" />
              </button>
            </div>
            {influencer && (
              <div className="row">
                <div className="col-4">
                  <Report
                    influencer={influencer}
                    createNote={createNote}
                    editNote={editNote}
                    removeNote={removeNote}
                    handleAddCardsToBoard={handleAddCardsToBoard}
                    openNotesModal={notesModal?.openModal}
                  />
                </div>
                <div className="col-8">
                  <TabContext value={mainTab}>
                    <TabList onChange={handleMainTabChange} className="main-tab-switch-container">
                      <Tab label="Influencer" value="influencer" className="main-tab-switch" />
                      <Tab label="Audience" value="audience" className="main-tab-switch" />
                    </TabList>
                    <TabPanel value="influencer" className="main-tab-content">
                      <TabContext value={secondaryTab}>
                        <TabList onChange={handleSecondaryTabChange} className="secondary-tab-switch-container">
                          {(influencer?.stats || influencer?.metrics) && <Tab label="Stats" value="influencerStats" className="secondary-tab-switch" />}
                          {influencer?.posts && <Tab label="Posts" value="influencerPosts" className="secondary-tab-switch" />}
                          {influencer?.lookalikes?.length > 0 && <Tab label="Lookalikes" value="influencerLookalikes" className="secondary-tab-switch" />}
                        </TabList>
                        <TabPanel value="influencerStats" className="secondary-tab-content">
                          <InfluencerStats stats={influencer?.stats} metrics={influencer?.metrics} />
                        </TabPanel>
                        <TabPanel value="influencerPosts" className="secondary-tab-content">
                          <Posts posts={influencer?.posts} />
                        </TabPanel>
                        <TabPanel value="influencerLookalikes" className="secondary-tab-content">
                          <Audience users={influencer?.lookalikes} />
                        </TabPanel>
                      </TabContext>
                    </TabPanel>
                    <TabPanel value="audience" className="main-tab-content">
                      <TabContext value={secondaryTab}>
                        <TabList onChange={handleSecondaryTabChange} className="secondary-tab-switch-container">
                          {influencer?.audiences?.length > 0 && <Tab label="Stats" value="audienceStats" className="secondary-tab-switch" />}
                          {influencer?.audiences?.reduce((total, audience) => total + (audience?.lookalikes?.length || 0), 0) > 0 && (
                            <Tab label="Lookalikes" value="audienceLookalikes" className="secondary-tab-switch" />
                          )}
                          {influencer?.audiences.some((audience) => audience.type === "followers") && (
                            <Tab label="Notable Followers" value="audienceNotableFollowers" className="secondary-tab-switch" />
                          )}
                          {influencer?.audiences.some((audience) => audience.type === "likers") && (
                            <Tab label="Notable Likers" value="audienceNotableLikers" className="secondary-tab-switch" />
                          )}
                          {influencer?.audiences.some((audience) => audience.type === "commenters") && (
                            <Tab label="Notable Commenters" value="audienceNotableCommenters" className="secondary-tab-switch" />
                          )}
                        </TabList>
                        <TabPanel value="audienceStats" className="secondary-tab-content">
                          {influencer?.audiences?.map((audience) => (
                            <AudienceStats title={`Audience data by ${audience.type}`} audience={audience} />
                          ))}
                        </TabPanel>
                        <TabPanel value="audienceLookalikes" className="secondary-tab-content">
                          {influencer?.audiences?.map((audience) => (
                            <Audience users={audience?.lookalikes} />
                          ))}
                        </TabPanel>
                        <TabPanel value="audienceNotableFollowers" className="secondary-tab-content">
                          <Audience users={influencer.audiences?.filter((audience) => audience.type === "followers")[0]?.notable_users} />
                        </TabPanel>
                        <TabPanel value="audienceNotableLikers" className="secondary-tab-content">
                          <Audience users={influencer.audiences?.filter((audience) => audience.type === "likers")[0]?.notable_users} />
                        </TabPanel>
                        <TabPanel value="audienceNotableCommenters" className="secondary-tab-content">
                          <Audience users={influencer.audiences?.filter((audience) => audience.type === "commenters")[0]?.notable_users} />
                        </TabPanel>
                      </TabContext>
                    </TabPanel>
                  </TabContext>
                </div>
              </div>
            )}
          </div>
        </Dialog>
      )}
      {(loading || shouldRefreshInsights) && <FullScreenLoader state={true} />}
      <NotesModal ref={notesModalRef} />
      <BookmarkModal ref={bookmarkModalRef} />
      <Modal
        isOpen={refreshModalIsOpen}
        closeModal={closeRefreshModal}
        callback={refreshReport}
        content="Are you sure you want to refresh this report ? This will use up 5 credits from your quota"
      />
    </>
  )
}

export default InfluencerInsights
