import { useDispatch } from "react-redux"
import { setCredits } from "../redux/reducers/authReducer"
import useBaseService from "./useBaseService"
import notify from "../components/Common/Toast"

const useInfluencerDetailsService = () => {
  const dispatch = useDispatch()
  const axiosRequest = useBaseService().axiosRequest()

  const insights = async (id, refresh) => {
    try {
      const response = await axiosRequest.get(`influencers/${id}/insights`, {
        params: {
          refresh
        }
      })
      dispatch(setCredits(response.data.meta.credits))
      return response.data.influencer
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const createNote = async (content, influencer_id) => {
    try {
      const response = await axiosRequest.post(`/influencers/${influencer_id}/notes`, {
        content,
      })
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const editNote = async (id, content) => {
    try {
      const response = await axiosRequest.put(`notes/${id}`, {
        content,
      })
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeNote = async (id) => {
    try {
      await axiosRequest.delete(`notes/${id}`)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  return {
    insights,
    createNote,
    editNote,
    removeNote,
  }
}

export default useInfluencerDetailsService
