import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setLogout } from "../redux/reducers/authReducer"

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL

const useBaseService = () => {
  const token = useSelector((state) => state.auth?.token)
  const dispatch = useDispatch()

  const axiosRequest = () => {
    const axiosBaseRequest = axios.create({
      baseURL: API_BASE_URL,
    })

    axiosBaseRequest.defaults.headers.common["content-type"] = "application/json"

    if (token) {
      axiosBaseRequest.defaults.headers.common["authorization"] = token
    }

    axiosBaseRequest.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          dispatch(setLogout())
        }
        return Promise.reject(error)
      }
    )

    return axiosBaseRequest
  }

  return {
    axiosRequest,
  }
}

export default useBaseService
