import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import PaymentSetupForm from "./paymentSetupForm";
import usePaymentMethodService from "../../../../services/usePaymentMethodService";
import { FormControl } from "react-bootstrap";
import CrossIcon from "../../../../assets/images/cross.svg";

const style = {
  display: "flex",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  padding: "24px",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  flexShrink: "0",
  margin: "auto",
  borderRadius: "15px",
  background: "#FFF",
  border: "none !important",
};

function StripePaymentMethodModal({
  setStripeModalOpen,
  stripeModalOpen,
  redirectionUrl,
}) {
  const [open, setOpen] = useState(stripeModalOpen);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const paymentMethodHookService = usePaymentMethodService();
  const handleOpen = () => setOpen(true);
  const handleStripeClose = () => {
    setStripeModalOpen(false);
  };
  const stripePromise = publishableKey ? loadStripe(publishableKey) : "";

  useEffect(() => {
    if (open) {
      paymentMethodHookService
        .setupIntent()
        .then((result) => {
          if (result) {
            setLoading(false);
            setPublishableKey(result.publishable_key);
            setClientSecret(result.client_secret);
          }
        })
        .catch((error) => {
          console.log(error.response.data.error);
        });
    }
  }, [open]);

  return (
    <div>
      <Modal
        className="stripe-payment-modal"
        open={open}
        onClose={handleStripeClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {!loading && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret: clientSecret,
                  appearance: {
                    theme: "minimal",
                    variables: {
                      fontFamily: "Open Sans, system-ui, sans-serif",
                    },
                  },
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h6>Add New Payment Method</h6>
                  <img
                    src={CrossIcon}
                    className="remove-button cursor-pointer"
                    alt=""
                    onClick={() => setStripeModalOpen(false)}
                  />
                </div>
                <div className="w-100" style={{ paddingRight: "20px" }}>
                  <label style={{ fontSize: "14px", marginBottom: "3px" }}>
                    Card Holder Name
                  </label>
                  <FormControl type="text" placeholder="John Smith" />
                </div>

                <PaymentSetupForm
                  setStripeModalOpen={setStripeModalOpen}
                  redirectionUrl={redirectionUrl}
                />
              </Elements>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default StripePaymentMethodModal;
