import React from "react"
import { ColorRing } from "react-loader-spinner"
import Logo from "../../../assets/images/hive-animated-logo.gif"
import "./styles.scss"

const Loader = ({ visible }) => {
  return <ColorRing visible={visible} height="30" width="30" colors={["#000000", "#000000", "#000000", "#000000", "#000000"]} wrapperClass="loader" />
}

const FullScreenLoader = ({ state }) => {
  return (
    state && (
      <div className="full-screen-loader">
        <div className="animated-logo">
          <img src={Logo} alt="" className="image" priority loading="eager" />
        </div>
      </div>
    )
  )
}

const LoadingOverlay = ({ visible }) => {
  return <>{visible && <div className="loading-overlay"></div>}</>
}

export { Loader, FullScreenLoader, LoadingOverlay }
