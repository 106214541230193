import React from "react"
import Skeleton from "@mui/material/Skeleton"
import { humanizeNumber } from "../../../helpers"
import "./styles.scss"
import CrossIcon from "../../../assets/images/small-cross.svg"

const SelectedFilters = ({ isLoading, influencerFilters, setInfluencerFilters, audienceFilters, setAudienceFilters }) => {
  const clearFilter = (type, key) => {
    if (type === "influencer") {
      setInfluencerFilters((prev) => {
        const updatedFilters = { ...prev }
        delete updatedFilters[key]
        return updatedFilters
      })
    } else if (type === "audience") {
      setAudienceFilters((prev) => {
        const updatedFilters = { ...prev }
        delete updatedFilters[key]
        return updatedFilters
      })
    }
  }

  const getDisplayKey = (type, key) => {
    switch (key) {
      case "accountTypes":
        return "Account Types"
      case "age":
        return type === "influencer" ? "Influencer Age" : "Audience Age"
      case "engagementRate":
        return "Engagement Rate"
      case "gender":
        return type === "influencer" ? "Influencer Gender" : "Audience Gender"
      case "hasContactDetails":
        return "Contacts"
      case "interests":
        return type === "influencer" ? "Influencer Interests" : "Audience Interests"
      case "language":
        return type === "influencer" ? "Influencer Language" : "Audience Languages"
      case "lastposted":
        return "Last Post"
      case "location":
        return type === "influencer" ? "Influencer Locations" : "Audience Locations"
      case "reelPlays":
        return "Reel Plays"
      case "followersGrowthRate":
        return "Growth Rate"
      default:
        return key
    }
  }

  const getDisplayValue = (type, key, value) => {
    if (type === "influencer") {
      switch (key) {
        case "accountTypes":
        case "brands":
        case "hasContactDetails":
        case "interests":
        case "location":
        case "topics":
        case "lookalikes":
        case "hashtags":
        case "mentions":
          return value.map((item) => item.label).join(", ")
        case "lastposted":
        case "language":
        case "gender":
        case "engagementRate":
        case "followersGrowthRate":
          return value.label
        case "bio":
        case "keywords":
          return value
        case "age":
        case "followers":
        case "reelsPlays":
        case "views":
          if (value.min && value.max) {
            return `Min: ${humanizeNumber(value.min)}, Max: ${humanizeNumber(value.max)}`
          } else if (value.min) {
            return `Min: ${humanizeNumber(value.min)}`
          } else if (value.max) {
            return `Max: ${humanizeNumber(value.max)}`
          }
          break
        default:
          return value
      }
    } else if (type === "audience") {
      switch (key) {
        case "age":
        case "interests":
        case "location":
          return value.map((item) => item.label).join(", ")
        case "language":
        case "gender":
        case "credibility":
          return value.label
        default:
          return value
      }
    }
  }

  return (
    <div className="selected-filters-container">
      {isLoading && <Skeleton variant="rounded" width={300} height={24} />}
      {!isLoading && (
        <>
          {Object.entries(influencerFilters).map(
            ([key, value]) =>
              value !== null &&
              value?.length !== 0 && (
                <div key={`influencer-${key}`} className="selected-filter">
                  <div className="text">
                    <div className="key">{getDisplayKey("influencer", key)}:</div>
                    <div className="value">{getDisplayValue("influencer", key, value)}</div>
                  </div>
                  <img src={CrossIcon} alt="" className="icon" onClick={() => clearFilter("influencer", key)} />
                </div>
              )
          )}
          {Object.entries(audienceFilters).map(
            ([key, value]) =>
              value !== null &&
              value?.length !== 0 && (
                <div key={`audience-${key}`} className="selected-filter">
                  <div className="text">
                    <div className="key">{getDisplayKey("audience", key)}:</div>
                    <div className="value">{getDisplayValue("audience", key, value)}</div>
                  </div>
                  <img src={CrossIcon} alt="" className="icon" onClick={() => clearFilter("audience", key)} />
                </div>
              )
          )}
        </>
      )}
    </div>
  )
}

export default SelectedFilters
