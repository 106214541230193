import useBaseService from "./useBaseService"
import { useDispatch } from "react-redux"
import { setUser, setToken, setCredits } from "../redux/reducers/authReducer"
import notify from "../components/Common/Toast"

const useProfileService = () => {
  const dispatch = useDispatch()
  const axiosRequest = useBaseService().axiosRequest()

  const updatePassword = async ({ currentPassword, newPassword, confirmPassword }) => {
    try {
      const response = await axiosRequest.put("users/update_password", {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmPassword,
      })
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const getProfile = async () => {
    try {
      const response = await axiosRequest.get("users/profile")
      dispatch(setCredits(response.data.credits))
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const profile = async () => {
    try {
      const response = await axiosRequest.get("users/profile")
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const updateProfile = async (formData) => {
    try {
      const response = await axiosRequest.put("users/update", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      notify.success("Profile information updated successfully")
      dispatch(setUser(response.data))
      dispatch(setToken(response.headers["authorization"]))
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  return {
    updatePassword,
    getProfile,
    profile,
    updateProfile,
  }
}

export default useProfileService
