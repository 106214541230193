// React import
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { FormControl } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Typography } from "@mui/material";

//Services or Apis
import { setUser, setCredits } from "../../../redux/reducers/authReducer";
import usePaymentMethodService from "../../../services/usePaymentMethodService";
import useSubscribedPlanService from "../../../services/useSubscribedPlanService";
import useProfileService from "../../../services/useProfileService";
import usePaymentPlanService from "../../../services/usePaymentPlanService";

// Components
import StripePaymentModal from "../../../components/Account/Payment/PaymentMethod/StripePaymentMethodModal";
import PaymentSetupForm from "../../../components/Account/Payment/PaymentMethod/paymentSetupForm";
import notify from "../../../components/Common/Toast";
import MasterCardLogo from "../../../assets/images/master_card_logo.svg";
import arrowIcon from "../../../assets/images/back_arrow.svg";
import TooltipIcon from "../../../assets/images/tooltip_icon.svg";
import AlertIcon from "../../../assets/images/payment_alert.svg";
import { FullScreenLoader } from "../../../components/Common/Loaders";

// Styles
import "./styles.scss";

const Checkout = () => {
  // React Service Constants
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Apis Service Constants
  const paymentPlanService = usePaymentPlanService();
  const paymentMethodService = usePaymentMethodService();
  const subscribedPlanService = useSubscribedPlanService();
  const profileService = useProfileService();

  //Component Constants
  const { id: planID } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const redirectSuccess = queryParams.get("redirect_status");
  const [planDuration, setPlanDuration] = useState(
    queryParams.get("planDuration") === "monthly" ? "monthly" : "annually"
  );

  // Use States
  const [plan, setPlan] = useState();
  const [planAndAmountHash, setPlanAndAmountHash] = useState();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [stripeModalOpen, setStripeModalOpen] = useState(false);
  const [priceId, setPriceId] = useState();
  const [price, setPrice] = useState();
  const [subscribedPlanId, setSubscribedPlanId] = useState();
  const [amountDue, setAmountDue] = useState();
  const [duplicateCardError, setDuplicateCardError] = useState();
  const [loading, setLoading] = useState(true);
  const [reloading, setReloading] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [publishableKey, setPublishableKey] = useState("");
  const [currentPlanDuration, setCurrentPlanDuration] = useState("");
  const [proceedToPayment, setProceedToPayment] = useState(
    queryParams.get("proceed_to_payment")
  );
  const stripePromise = publishableKey ? loadStripe(publishableKey) : "";

  //Use Effects
  useEffect(() => {
    paymentPlanService
      .show(planID)
      .then((data) => {
        setPlan(data.payment_plan);
        setPlanAndAmountHash(data.meta["subscribed_plan_amount_left"]);
      })
      .catch((error) => {
        navigate("/brand/plans");
      });
    if (!proceedToPayment) {
      paymentMethodService.list().then((data) => {
        setPaymentMethods(data);
        setSelectedPaymentMethod(data[0]);
      });
    }
  }, []);

  useEffect(() => {
    paymentMethodService
      .setupIntent()
      .then((result) => {
        if (result) {
          setLoading(false);
          setPublishableKey(result.publishable_key);
          setClientSecret(result.client_secret);
        }
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
  }, [!reloading]);

  useEffect(() => {
    if (plan) {
      setPriceId(plan.prices_hash[`${planDuration}`]["id"]);
      setPrice(plan.prices_hash[`${planDuration}`]["amount"]);
      setSubscribedPlanId(planAndAmountHash ? planAndAmountHash["id"] : "");
      setCurrentPlanDuration(
        planAndAmountHash ? planAndAmountHash["duration"] : ""
      );
    }
  }, [plan, planDuration]);

  useEffect(() => {
    setAmountDue(planDuration === "monthly" ? price : price * 12);
  }, [price, planDuration]);

  useEffect(() => {
    if (redirectSuccess) {
      setProceedToPayment(true);
      setTimeout(() => {
        const updatedUrl = window.location.origin + window.location.pathname;
        profileService.profile().then((data) => {
          setDuplicateCardError(data?.payment_method_error_message);
          if (data?.payment_method_error_message) {
            notify.error("Payment method not addded");
            setProceedToPayment(false);
          } else {
            if (proceedToPayment) {
              handleProceedToPayment();
            } else {
              setProceedToPayment(false);
              notify.success("Payment method added successfully.");
            }
          }
          window.history.replaceState(
            {},
            document.title,
            `${updatedUrl}?planDuration=${planDuration}`
          );
        });
      }, 2000);
    }
  }, []);

  //Handlers
  const handleCreateSubscribedPlan = (
    selectedPriceId = "",
    selectedPaymentMethodId = ""
  ) => {
    hideSpinner();
    let spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.classList.remove("d-none");
    }
    subscribedPlanService
      .create(
        selectedPriceId || priceId,
        selectedPaymentMethodId || selectedPaymentMethod["id"]
      )
      .then((data) => {
        if (data) {
          dispatch(setUser(data));
          dispatch(setCredits(data.credits));
          notify.success("Payment plan subscribed successfully");
          navigate("/brand/dashboard");
        }
      });
  };

  const handleUpgradeSubscribedPlan = () => {
    hideSpinner();
    subscribedPlanService
      .upgrade(subscribedPlanId, priceId, selectedPaymentMethod["id"])
      .then((data) => {
        if (data) {
          dispatch(setUser(data));
          dispatch(setCredits(data.credits));
          notify.success("Payment plan upgraded successfully");
          navigate("/brand/dashboard");
        } else {
          notify.error("An error occured while upgrading plan.");
        }
      });
  };

  const handleSelectedPaymentMethod = (id) => {
    paymentMethods.map((paymentMethod) => {
      if (paymentMethod.id === id) {
        setSelectedPaymentMethod(paymentMethod);
      }
    });
  };

  const handleProceedToPayment = () => {
    paymentPlanService
      .show(planID)
      .then((data) => {
        let proceededPriceId =
          data.payment_plan.prices_hash[`${planDuration}`]["id"];

        paymentMethodService.list().then((data) => {
          handleCreateSubscribedPlan(proceededPriceId, data[0].id);
        });
      })
      .catch((error) => {
        navigate("/brand/plans");
      });
  };

  const handleSwitchState = (duration) => {
    if (!(currentPlanDuration === "annually" && duration === "monthly")) {
      setPlanDuration(duration);
    }
  };

  // helpers
  const options = paymentMethods.map((option) => ({
    label: `************${option.last4_digits}`,
    value: option.id,
  }));

  const hideSpinner = () => {
    let spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.classList.remove("d-none");
    }
  };

  //Component HTML
  return (
    <div>
      {proceedToPayment && <FullScreenLoader state={true} />}
      <div>
        <div className="ml-80"></div>
        <div className="row p-80">
          <div
            className="ms-4 cursor-pointer"
            onClick={() => navigate("/brand/plans")}
          >
            <img src={arrowIcon} alt="" />
          </div>
          <div className="col-md-6 p-5">
            <h5>Subscribe</h5>
            <div className="checkout-platform-switch-container">
              <button
                className={`platform-switch ${
                  planDuration === "monthly" ? "active" : ""
                }`}
                onClick={() => {
                  handleSwitchState("monthly");
                }}
              >
                {currentPlanDuration && currentPlanDuration === "annually" && (
                  <Tooltip
                    title="You cannot downgrade from annual to a monthly plan."
                    placement="top"
                    arrow
                  >
                    <img
                      className="ms-2 cursor-pointer"
                      src={TooltipIcon}
                      style={{ width: "13px" }}
                    />
                  </Tooltip>
                )}
                <div className="name">Monthly</div>
              </button>
              <button
                className={`platform-switch ${
                  planDuration === "annually" ? "active" : ""
                }`}
                onClick={() => {
                  handleSwitchState("annually");
                }}
              >
                <div className="name">Yearly</div>
              </button>
              <span className="plan-discount-badge plan-discount-badge-position">
                Save 20%
              </span>
            </div>
            <div className="highlight-section active-plan-detail mt-2">
              <div>{plan?.name}</div>
              <div>
                <span className="price-text">${price}</span> per month
              </div>
            </div>
            <div className="subscription-note detail-text-heading">
              {planDuration === "monthly"
                ? "You can easily manage your subscription, change plan or cancel anytime."
                : "You’re free to change or cancel anytime—your subscription just runs for a year. "}
            </div>
            <div className="d-flex justify-content-between align-items-end mt-3 position-relative">
              <div className="d-flex flex-column">
                <div className="detail-text-heading">{plan?.name}</div>
                <div className="sub-heading-text mt-1">
                  Billed {planDuration}
                </div>
              </div>
              <div className="d-flex flex-column align-items-end">
                {plan && planDuration === "annually" && (
                  <Typography
                    sx={{
                      textDecoration: "line-through",
                      fontSize: "15px",
                      display: "inline-block",
                    }}
                  >
                    $
                    {plan.prices_hash[
                      `${planDuration == "monthly" ? "annually" : "monthly"}`
                    ]["amount"].toFixed(2)}
                  </Typography>
                )}
                <span className="billed-price">${price?.toFixed(2)}</span>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <span className="detail-text-heading">Tax</span>
              <span>$0.00</span>
            </div>
            <hr />
            {plan && planDuration === "annually" && (
              <div>
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="detail-text-heading me-2 ">Saved</span>
                    <Tooltip
                      title="Amount saved annually due to annual plan"
                      placement="top"
                      arrow
                    >
                      <img
                        className="cursor-pointer"
                        src={TooltipIcon}
                        style={{ width: "13px" }}
                        alt=""
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <span className="plan-discount-badge plan-discount-position p-1 me-2">
                      Save 20%
                    </span>
                    <span className="font-15px">
                      - $
                      {(
                        plan.prices_hash["monthly"]["amount"] * 12 -
                        amountDue
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
            )}

            <div className="d-flex justify-content-between">
              <span className="billed-price">Total</span>
              <span className="billed-price">
                ${amountDue?.toFixed(2)}{" "}
                {!planAndAmountHash && <span className="font-12px">(USD)</span>}
              </span>
            </div>
            {planAndAmountHash && planAndAmountHash["amount_left"] > 0 && (
              <div>
                <hr />
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="detail-text-heading higlighted-text me-2 ">
                      Prorated Refund
                    </span>
                    <Tooltip
                      title="Remaining unused credits price calculated from previous plans"
                      placement="top"
                      arrow
                    >
                      <img
                        className="cursor-pointer"
                        src={TooltipIcon}
                        style={{ width: "13px" }}
                        alt=""
                      />
                    </Tooltip>
                  </div>
                  <span className="higlighted-text">
                    - ${planAndAmountHash["amount_left"]?.toFixed(2)}
                  </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <span className="billed-price">Grand Total</span>
                  <span className="billed-price">
                    ${(amountDue - planAndAmountHash["amount_left"]).toFixed(2)}
                    <span className="font-12px"> (USD)</span>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div
            className="col-md-6"
            style={{
              padding: "40px",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.04)",
              height: "fit-content",
              marginTop: "40px",
            }}
          >
            {duplicateCardError && (
              <div className="payment-alert-detail-section text-center mb-3">
                <img
                  className="alert-icon-detail mx-2"
                  src={AlertIcon}
                  alt=""
                />
                {duplicateCardError}
              </div>
            )}
            {paymentMethods.length > 0 && (
              <div>
                <h6>Payment Method</h6>
                <div className="select-field-container mt-4 col-12 col-md-8">
                  <Select
                    options={options}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Select an option"
                    value={
                      selectedPaymentMethod
                        ? {
                            label: `************${selectedPaymentMethod.last4_digits}`,
                            value: selectedPaymentMethod.id,
                          }
                        : options[0]
                    }
                    onChange={(selectedOption) =>
                      handleSelectedPaymentMethod(selectedOption.value)
                    }
                    noOptionsMessage={() => "No payment method added"}
                  />
                </div>

                {selectedPaymentMethod && (
                  <div className="d-flex align-items-start mt-4 mb-4">
                    <div className="card-bg-black me-3">
                      <img src={MasterCardLogo} alt="" />
                    </div>
                    <div className="d-flex flex-column">
                      <span className="detail-text">
                        **** **** ****{selectedPaymentMethod.last4_digits}
                      </span>
                      <span className="mt-2 detail-text">
                        Expires {selectedPaymentMethod.exp_month}/
                        {selectedPaymentMethod.exp_year}
                      </span>
                    </div>
                    <span class="badge rounded-pill bg-dark ms-3">
                      Selected
                    </span>
                  </div>
                )}
                <hr />
                <div
                  className="new-payment-text cursor-pointer mt-4"
                  onClick={() => {
                    setStripeModalOpen(true);
                  }}
                >
                  + Add New Payment Method
                </div>
                {stripeModalOpen && (
                  <StripePaymentModal
                    stripeModalOpen={stripeModalOpen}
                    setStripeModalOpen={setStripeModalOpen}
                    redirectionUrl={`${
                      window.location.href.split("?")[0]
                    }?planDuration=${planDuration}`}
                  />
                )}
                <div className="mt-4">
                  <button
                    className="submit-button "
                    onClick={
                      planAndAmountHash
                        ? handleUpgradeSubscribedPlan
                        : handleCreateSubscribedPlan
                    }
                  >
                    <div
                      class="spinner-border spinner-scale me-2 d-none text-light"
                      role="status"
                      id="spinner"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Upgrade
                  </button>
                </div>
              </div>
            )}
            {paymentMethods.length == 0 && (
              <div>
                {!loading && (
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: clientSecret,
                      appearance: {
                        theme: "minimal",
                        variables: {
                          fontFamily: "Open Sans, system-ui, sans-serif",
                        },
                      },
                    }}
                  >
                    <h6>Payment</h6>
                    <div className="w-100" style={{ paddingRight: "20px" }}>
                      <label
                        style={{
                          fontSize: "14px",
                          marginBottom: "3px",
                          marginTop: "10px",
                        }}
                      >
                        Card Holder Name
                      </label>
                      <FormControl type="text" placeholder="John Smith" />
                    </div>

                    <PaymentSetupForm
                      setStripeModalOpen={setStripeModalOpen}
                      redirectionUrl={`${
                        window.location.href.split("?")[0]
                      }?planDuration=${planDuration}&proceed_to_payment=true`}
                      submitBtnText="Subscribe"
                      reloading={reloading}
                      setReloading={setReloading}
                    />
                  </Elements>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
