import useBaseService from "./useBaseService"
import notify from "../components/Common/Toast"

const usePaymentPlanService = () => {
  const axiosRequest = useBaseService().axiosRequest()

  const list = async () => {
    try {
      const response = await axiosRequest.get("payment_plans")
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const show = async (id) => {
    try {
      const response = await axiosRequest.get(`payment_plans/${id}`)
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  return {
    list,
    show,
  }
}

export default usePaymentPlanService
