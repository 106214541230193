import React, { useState } from "react"
import Header from "../../../components/Common/Header"
import FindInfluencers from "../FindInfluencers"
import ManageInfluencers from "../ManageInfluencers"
import InfluencerInsights from "../InfluencerInsights"
import Boards from "../Boards"

const BrandDashboard = () => {
  const [updateSavedInfluencers, setUpdateSavedInfluencers] = useState(false)
  const [updateBoards, setUpdateBoards] = useState(false)
  const [openDetails, setOpenDetails] = useState(null)

  return (
    <>
      <Header />
      <div className="tab-content">
        <div className="tab-pane fade show active" id="find-influencers-page">
          <FindInfluencers updateBoards={setUpdateBoards} openDetails={setOpenDetails} />
        </div>
        <div className="tab-pane fade" id="manage-influencers-page">
          <ManageInfluencers
            updateSavedInfluencers={updateSavedInfluencers}
            setUpdateSavedInfluencers={setUpdateSavedInfluencers}
            updateBoards={setUpdateBoards}
            openDetails={setOpenDetails}
          />
        </div>
        <div className="tab-pane fade" id="boards-page">
          <Boards updateBoards={updateBoards} openDetails={setOpenDetails} />
        </div>
      </div>
      <InfluencerInsights updateSavedInfluencers={setUpdateSavedInfluencers} updateBoards={setUpdateBoards} influencerId={openDetails} setInfluencerId={setOpenDetails} />
    </>
  )
}

export default BrandDashboard
