import useBaseService from "./useBaseService";
import notify from "../components/Common/Toast";

const useSubscribedPlanService = () => {
  const axiosRequest = useBaseService().axiosRequest();

  const create = async (priceId, paymentMethodId) => {
    try {
      const response = await axiosRequest.post("subscribed_plans", {
        price_id: priceId,
        payment_method_id: paymentMethodId,
      });
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const detail = async () => {
    try {
      const response = await axiosRequest.get("subscribed_plans/detail");
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const cancel = async (id) => {
    try {
      const response = await axiosRequest.put(`subscribed_plans/${id}/cancel`, {
        status: "cancelled",
      });
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const upgrade = async (id, priceId, paymentMethodId) => {
    try {
      const response = await axiosRequest.put(
        `subscribed_plans/${id}/upgrade`,
        {
          price_id: priceId,
          payment_method_id: paymentMethodId,
        }
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const triggerPayment = async (id) => {
    try {
      const response = await axiosRequest.post(
        `subscribed_plans/${id}/trigger_payment`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  const reactivateCancelledPlan = async (id) => {
    try {
      const response = await axiosRequest.post(
        `subscribed_plans/${id}/reactivate_cancelled_plan`
      );
      return response.data;
    } catch (error) {
      notify.error(error.response.data.error);
      return false;
    }
  };

  return {
    create,
    detail,
    cancel,
    upgrade,
    triggerPayment,
    reactivateCancelledPlan,
  };
};

export default useSubscribedPlanService;
