import React, { useState } from "react"
import { useFormik } from "formik"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import useAuthService from "../../../services/useAuthService"
import { PasswordInput, LoadingButton } from "../../../components/Common/CommonElements"
import "./styles.scss"
import Logo from "../../../assets/images/logo.svg"
import LockIcon from "../../../assets/images/lock.svg"

const ResetPassword = () => {
  const navigate = useNavigate()
  const authService = useAuthService()
  const [loading, setLoading] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      resetPasswordToken: urlParams.get("token"),
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|[\W_]).+$/, "Password must contain upper and lower case characters, and at least one number or symbol"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true)
      authService
        .resetPassword(values)
        .then((data) => {
          if (data) {
            navigate("/login")
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  const { values, touched, errors } = formik

  return (
    <div className="reset-password-page">
      <div className="logo-container">
        <img src={Logo} className="logo" alt="logo" />
      </div>
      <div className="form-container">
        <form onSubmit={formik.handleSubmit} className="form">
          <div className="icon-container">
            <img src={LockIcon} className="icon" alt="" />
          </div>
          <div className="primary-text mb-4">Enter Your New Password</div>
          <div className="form-fields-container">
            <div className="form-field-container">
              <PasswordInput
                label="Password"
                name="password"
                value={values.password}
                onChange={formik.handleChange("password")}
                error={touched.password && errors.password}
              />
            </div>
            <div className="form-field-container">
              <PasswordInput
                label="Confirm Password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={formik.handleChange("confirmPassword")}
                error={touched.confirmPassword && errors.confirmPassword}
              />
            </div>
          </div>
          <LoadingButton
            type="submit"
            label="Update Password"
            theme={{ backgroundColor: "#000000", color: "#ffffff", padding: "0.8rem 2.2rem", fontSize: "14px", width: "100%" }}
            isLoading={loading}
          />
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
