import React, { useState, forwardRef, useImperativeHandle } from "react"
import { Dialog } from "@mui/material"
import * as Yup from "yup"
import { useFormik } from "formik"
import { LoadingButton, TextInput } from "../../Common/CommonElements"
import "./styles.scss"
import CrossIcon from "../../../assets/images/cross.svg"

const BoardFormModal = forwardRef(({}, ref) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [value, setValue] = useState(null)
  const [callback, setCallback] = useState(null)
  const [title, setTitle] = useState(null)

  const openModal = (id, value, callback, title) => {
    setId(id)
    setValue(value)
    setTitle(title)
    setCallback(() => callback)
    setOpen(true)
  }

  const closeModal = () => {
    setId(null)
    setValue(null)
    setCallback(false)
    setTitle(false)
    resetForm()
    setOpen(false)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: id,
      name: value,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      callback(values)
    },
  })

  const { values, touched, errors, resetForm } = formik

  useImperativeHandle(ref, () => ({
    openModal,
    closeModal,
    setLoading,
  }))

  return (
    <Dialog open={open} onClose={closeModal} className="form-modal">
      <div className="modal-container">
        <button className="close-button" onClick={closeModal}>
          <img src={CrossIcon} alt="close" className="icon" />
        </button>
        <div className="modal-title">{title}</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-field-container">
            <TextInput label="Name" name="name" value={values.name} placeholder="" onChange={formik.handleChange("name")} error={touched.name && errors.name} />
          </div>
          <LoadingButton
            type="submit"
            label="Submit"
            isLoading={loading}
            theme={{ backgroundColor: "#000000", color: "white", padding: "0.8rem 2.2rem", fontSize: "14px" }}
          />
        </form>
      </div>
    </Dialog>
  )
})

export default BoardFormModal
