import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../Common/CommonElements"
import "./styles.scss"

const UpgradePopup = ({ content }) => {
  const navigate = useNavigate()

  return (
    <div className="boards-limit-upgrade-popup">
      <div className="text">{content}</div>
      <Button
        type="button"
        label="Upgrade"
        onClick={() => navigate("/brand/plans")}
        theme={{ backgroundColor: "#94a4ff", color: "#ffffff", padding: "0.5rem 0.8rem", fontSize: "12px" }}
      />
    </div>
  )
}

export default UpgradePopup
