import React from "react"
import { platforms } from "../../../constants"
import "./styles.scss"
import InstagramProfileActiveIcon from "../../../assets/images/platform-switch-instagram-active.svg"
import InstagramProfileInActiveIcon from "../../../assets/images/platform-switch-instagram-inactive.svg"
import YoutubeProfileActiveIcon from "../../../assets/images/platform-switch-youtube-active.svg"
import YoutubeProfileInActiveIcon from "../../../assets/images/platform-switch-youtube-inactive.svg"
import TiktokProfileActiveIcon from "../../../assets/images/platform-switch-tiktok-active.svg"
import TiktokProfileInActiveIcon from "../../../assets/images/platform-switch-tiktok-inactive.svg"

const PlatformSwitch = ({ currentPlatform, onPlatformChange }) => {
  return (
    <div className="platform-switch-container">
      <button className={`platform-switch ${currentPlatform === platforms[0] ? "active" : ""}`} onClick={() => onPlatformChange(platforms[0])}>
        <div className="icon-container">
          <img src={currentPlatform === platforms[0] ? InstagramProfileActiveIcon : InstagramProfileInActiveIcon} className="icon" alt="" />
        </div>
        <div className="name">Instagram</div>
      </button>
      <button className={`platform-switch ${currentPlatform === platforms[1] ? "active" : ""}`} onClick={() => onPlatformChange(platforms[1])}>
        <div className="icon-container">
          <img src={currentPlatform === platforms[1] ? YoutubeProfileActiveIcon : YoutubeProfileInActiveIcon} className="icon" alt="" />
        </div>
        <div className="name">Youtube</div>
      </button>
      <button className={`platform-switch ${currentPlatform === platforms[2] ? "active" : ""}`} onClick={() => onPlatformChange(platforms[2])}>
        <div className="icon-container">
          <img src={currentPlatform === platforms[2] ? TiktokProfileActiveIcon : TiktokProfileInActiveIcon} className="icon" alt="" />
        </div>
        <div className="name">TikTok</div>
      </button>
    </div>
  )
}

export default PlatformSwitch
