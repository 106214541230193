import useBaseService from "./useBaseService"
import notify from "../components/Common/Toast"

const useBoardsService = () => {
  const axiosRequest = useBaseService().axiosRequest()

  const index = async () => {
    try {
      const response = await axiosRequest.get("boards")
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const show = async (id, filters) => {
    try {
      const response = await axiosRequest.get(`boards/${id}`, {
        params: {
          query: filters?.query,
          engagement_rate: filters?.engagement?.value,
          followers: filters?.followers,
        },
      })
      return response.data
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const updatePositions = async (id, board) => {
    try {
      await axiosRequest.post(`boards/${id}/update_positions`, {
        board,
      })
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const createBoard = async (name) => {
    try {
      const response = await axiosRequest.post("boards", {
        name,
      })
      notify.success(response.data.message)
      return response.data.board
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const editBoard = async (id, name) => {
    try {
      const response = await axiosRequest.put(`boards/${id}`, {
        name,
      })
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeBoard = async (id) => {
    try {
      const response = await axiosRequest.delete(`boards/${id}`)
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const createColumn = async (name, board_id) => {
    try {
      const response = await axiosRequest.post(`boards/${board_id}/columns`, {
        name,
      })
      notify.success(response.data.message)
      return response.data.column
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const editColumn = async (id, name) => {
    try {
      const response = await axiosRequest.put(`columns/${id}`, {
        name,
      })
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeColumn = async (id) => {
    try {
      const response = await axiosRequest.delete(`/columns/${id}`)
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const createCard = async (values) => {
    try {
      const response = await axiosRequest.post(`columns/${values.column}/cards`, {
        board_id: values.board,
        influencer_ids: values.influencers,
        content_platform: values.platform,
        content_type: values.contentType,
        content_amount: values.contentAmount,
        fees_currency: values.currency,
        fees_value: values.fee,
        tags: values.tags,
      })
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeCard = async (id) => {
    try {
      const response = await axiosRequest.delete(`/cards/${id}`)
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const createTag = async (id, content, color) => {
    try {
      const response = await axiosRequest.post(`cards/${id}/tags`, {
        tag: content,
        color: color,
      })
      notify.success(response.data.message)
      return response.data.tag
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  const removeTag = async (id) => {
    try {
      const response = await axiosRequest.delete(`/tags/${id}`)
      notify.success(response.data.message)
      return true
    } catch (error) {
      notify.error(error.response.data.error)
      return false
    }
  }

  return {
    index,
    show,
    updatePositions,
    createBoard,
    editBoard,
    removeBoard,
    createColumn,
    editColumn,
    removeColumn,
    createCard,
    removeCard,
    createTag,
    removeTag,
  }
}

export default useBoardsService
